import React, { useEffect } from 'react';
import ChartElem from '../../../../Components/ChartElem';

const Graphe = ({
  state
}) => {

  useEffect(() => {

  }, [])


  return (
    <ChartElem
      dataForChart={{
        chartTitle: 'Géométrie',
        value: {
          structure: [
            state.data.SML,
            state.data.BEC,
            state.data.M,
            state.data.FG,
            state.data.FD,
          ],
          soil: [
            state.data.RG,
            state.data.RD,
            state.data.RDD
          ],
          water: [
            state.data.HEG,
            state.data.HED,
          ],
          loads: [
            state.data.QD.value
          ],
          // points: [
          //   { name}
          // ]
        },
        axisName: { x: 'Longueur', y: 'Elévation' },
        unit: { x: 'm', y: 'm' }
      }}
    />
  )
}

export default Graphe;