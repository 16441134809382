import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    // margin: theme.spacing(1),
    maxWidth: 350,
    border: '0px'
  }
}));

const SelectElem = ({
  value,
  text,
  list,
  onChange
}) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.select}
      id={text}
      fullWidth
      select
      label={text}
      value={value}
      onChange={onChange}
      variant="standard"
      InputProps={{ disableUnderline: true }}
    >
      {list.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectElem;