import General from './General';
import Concrete from './Concrete';
import ExternalStability from './ExternalStability';

class InternalStability extends General {
  constructor(data, standards) {
    super(data);
    this.data = data;
    this.standards = standards;
    this.concrete = new Concrete(this.data, "Eurocode2");
    this.externalStability = new ExternalStability(this.data);
  }

  // Solliciations de poids propre
  sollicitationsPoidsPropres() {
    const Hp = this.data.Hp.value;
    const ep = this.data.ep.value;
    const fam = this.data.fam.value;
    const fav = this.data.fav.value;
    const es = this.data.es.value;
    const gammac = this.data.gammac.value;

    let N = gammac * Hp * (ep + fam / 2 + fav / 2);
    let V = 0;
    let M = 0;
    return {
      id: "sPP",
      name: "sPP",
      label: "sPP",
      description: "Sollicitations dues au poids propre",
      loadCase: "G0",
      N: this.round(N, 3),
      V: this.round(V, 3),
      M: this.round(M, 3)
    }
  }

  // Solliciations de remblai
  sollicitationsRemblai() {
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const es = this.data.es.value;

    const Ztop = Math.min(Ht, Hp) + es;
    const Zbot = es;

    const soilPressure = this.externalStability.pousseesTerrain();

    const soilPressureOnWall = soilPressure.filter(force => {
      if (Zbot <= force.Z && force.Z <= Ztop) {
        return force;
      }
    });

    const N = soilPressureOnWall.reduce((nsum, ni) => nsum + ni.dV, 0);
    const V = soilPressureOnWall.reduce((nsum, ni) => nsum + ni.dH, 0);
    const M = soilPressureOnWall.reduce((nsum, ni) => nsum + ni.dH * (ni.Z - Zbot), 0);


    return {
      id: "sR",
      name: "sR",
      label: "sR",
      description: "Sollicitations du à la poussée des terres",
      loadCase: "G1",
      N: this.round(N, 3),
      V: this.round(V, 3),
      M: this.round(M, 3)
    }
  }

  // Solliciations de charges d'exploitation
  sollicitationsChargesExploitation() {
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const es = this.data.es.value;

    const Ztop = Math.min(Ht, Hp) + es;
    const Zbot = es;

    let N = 0;
    let V = 0;
    let M = 0;

    const chargesSurRemblai = this.data.chargesSurRemblai.value;

    for (let chargeSurRemblai of chargesSurRemblai) {
      const {
        xi,
        xj,
        Qi,
        Qj,
        type
      } = chargeSurRemblai;
      const loadsPressure = this.externalStability.pousseesChargesRemblai({ xi, xj, Qi, Qj, type });
      const loadsPressureOnWall = loadsPressure.filter(force => {
        if (Zbot <= force.Z && force.Z <= Ztop) {
          return force;
        }
      });

      N += loadsPressureOnWall.reduce((nsum, ni) => nsum + ni.dV, 0);
      V += loadsPressureOnWall.reduce((nsum, ni) => nsum + ni.dH, 0);
      M += loadsPressureOnWall.reduce((nsum, ni) => nsum + ni.dH * (ni.Z - Zbot), 0);

    }

    return {
      id: "sCE",
      name: "sCE",
      label: "sCE",
      description: "Sollicitations du aux charges d'exploitation",
      loadCase: "Q",
      N: this.round(N, 3),
      V: this.round(V, 3),
      M: this.round(M, 3)
    }
  }

  // Solliciations hydrostatique
  sollicitationsHydostatique() {
    const gammae = this.data.gammae.value;
    const He1 = this.data.He1.value;
    const Hp = this.data.Hp.value;
    const es = this.data.es.value;

    const Ztop = Math.min(He1, Hp) + es;
    const Zbot = es;

    const waterPressure = this.externalStability.pousseesHydrostatique({ Zw: He1, type: "amont", gammae });
    const waterPressureOnWall = waterPressure.filter(force => {
      if (Zbot <= force.Z && force.Z <= Ztop) {
        return force;
      }
    });

    const N = waterPressureOnWall.reduce((nsum, ni) => nsum + ni.dV, 0);
    const V = waterPressureOnWall.reduce((nsum, ni) => nsum + ni.dH, 0);
    const M = waterPressureOnWall.reduce((nsum, ni) => nsum + ni.dH * (ni.Z - Zbot), 0);


    return {
      id: "sH",
      name: "sH",
      label: "sH",
      description: "Sollicitations du à la poussée hydrostatique",
      loadCase: "E",
      N: this.round(N, 3),
      V: this.round(V, 3),
      M: this.round(M, 3)
    }
  }


  // Recapitulatif des sollicitations
  casDeCharges() {
    const casDeCharges = this.data.casDeCharges;
    return casDeCharges;
  }

  recapitulatifSollicitations() {
    const sollicitations = [
      this.sollicitationsPoidsPropres(),
      this.sollicitationsRemblai(),
      this.sollicitationsChargesExploitation(),
      this.sollicitationsHydostatique()
    ];

    const casDeCharges = this.casDeCharges();
    const bilan = casDeCharges.value.map((casDeCharge) => {
      const N = sollicitations.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.N : nsum, 0);
      const V = sollicitations.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.V : nsum, 0);
      const M = sollicitations.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.M : nsum, 0);

      return {
        ...casDeCharge,
        N: this.round(N, 3),
        V: this.round(V, 3),
        M: this.round(M, 3)
      }
    })

    return bilan;
  }

  combinaisonsDeCharges() {
    const combinaisonsDeCharges = [];
    const combiList = this.data.combinaisonsDeCharges.value;
    for (let combi of combiList) {
      if (combi.type === "ELU" && combi.categorie === "STR") {
        combinaisonsDeCharges.push(combi);
      }
    }
    return {
      ...this.data.combinaisonsDeCharges,
      value: combinaisonsDeCharges
    };
  }

  calculStabiliteInterne() {
    const g = 9.81;
    const pi = Math.PI;
    const phi = this.data.phi.value;


    const combinaisonsDeCharges = this.combinaisonsDeCharges();
    const recapitulatifSollicitations = this.recapitulatifSollicitations();

    const effortsSurSol = this.externalStability.calculStabiliteExterne();

    const calculStabiliteInterne = combinaisonsDeCharges.value.map(combinaison => {
      const { loadCases } = combinaison;
      let N = 0;
      let V = 0;
      let M = 0;
      for (let loadCase of loadCases) {
        for (let bilanEffort of recapitulatifSollicitations) {
          if (bilanEffort.value === loadCase.id) {
            N += bilanEffort.N * loadCase.value;
            V += bilanEffort.V * loadCase.value;
            M += bilanEffort.M * loadCase.value;
          }
        }
      }

      const ep = this.data.ep.value;
      const es = this.data.es.value;
      const fam = this.data.fam.value;
      const dav = this.data.dav.value;
      const fav = this.data.fav.value;
      const b = 1;
      const h = ep + fam + fav;
      const c = this.data.c.value;
      const fck = this.data.fck.value;
      const Ecm = 31500;
      const alphacc = this.data.alphacc.value;
      const gammacc = this.data.gammacc.value;
      const fyk = this.data.fyk.value;
      const Es = 210000;
      const gammas = this.data.gammas.value;
      // Sollicitations au niveau de la base du parement
      const Ned = N * g;
      const Med = M * g;
      // Efforts sur la semelle
      const effortSurSol = effortsSurSol.find(effort => effort.id === combinaison.id);

      const Nsemelle = effortSurSol.V * g;
      const Msemelle = effortSurSol.M * g;

      const e = Msemelle / Nsemelle;
      const B = this.externalStability.Ls();
      const lc = dav + fav;
      const sigma1 = this.externalStability.soil.wall.contraintesSurSol({ e, B, N: Nsemelle, x: 0 });
      const sigma2 = this.externalStability.soil.wall.contraintesSurSol({ e, B, N: Nsemelle, x: lc });

      const MedG = e < 0 ? 0 : (sigma1 + sigma2) / 4 * lc ** (2);
      const MedD = e < 0 ? 0 : M * 10 - MedG;
      console.log('Med', Med)
      // Section d'acier dans la partie courante du mur à 1m de l'ancrage
      const {
        Asinf: Asc
      } = this.concrete.currentStandard.sectionRectangulaireFlexionCompression({
        b,
        c,
        h,
        fck,
        Ecm,
        alphacc,
        gammacc,
        fyk,
        Es,
        gammas,
        Ned: 0,
        Med: Med
      });

      // Section d'acier au niveau de l'ancrage du mur
      const {
        Asinf: Asb,
        mud: mub,
        zu: zub 
      } = this.concrete.currentStandard.sectionRectangulaireFlexionCompression({
        b,
        c,
        h,
        fck,
        Ecm,
        alphacc,
        gammacc,
        fyk,
        Es,
        gammas,
        Ned: 0,
        Med: Med
      });

      // Armature de fondations - Nappe Inférieure
      const {
        Asinf: Asfi
      } = this.concrete.currentStandard.sectionRectangulaireFlexionCompression({
        b,
        c,
        h: es,
        fck,
        Ecm,
        alphacc,
        gammacc,
        fyk,
        Es,
        gammas,
        Ned: 0,
        Med: MedG
      });

      // Armature de fondations - Nappe Supérieure
      const {
        Asinf: Asfsup
      } = this.concrete.currentStandard.sectionRectangulaireFlexionCompression({
        b,
        c,
        h: es,
        fck,
        Ecm,
        alphacc,
        gammacc,
        fyk,
        Es,
        gammas,
        Ned: 0,
        Med: MedD
      });

      return {
        ...combinaison,
        N: this.round(N, 3),
        V: this.round(V, 3),
        M: this.round(M, 3),
        Asc: this.round(Asc, 3),
        mub: this.round(mub, 3),
        zub: this.round(zub, 3),
        Asb: this.round(Asb, 3),
        Nsemelle: this.round(Nsemelle / g, 3),
        Msemelle: this.round(Msemelle / g, 3),
        e: this.round(e, 3),
        sigma1: this.round(sigma1, 3),
        sigma2: this.round(sigma2, 3),
        MedG: this.round(MedG / g, 3),
        MedD: this.round(MedD / g, 3),
        Asfi: this.round(Asfi, 3),
        Asfsup: this.round(Asfsup, 3)
      }
    })

    return calculStabiliteInterne;
  }


  Asc() {
    const calculStabiliteInterne = this.calculStabiliteInterne();
    const Asmax = calculStabiliteInterne.reduce((a, b) => a.Asc > b.Asc ? a : b);
    return Asmax.Asc;
  }

  Asb() {
    const calculStabiliteInterne = this.calculStabiliteInterne();
    const Asmax = calculStabiliteInterne.reduce((a, b) => a.Asb > b.Asb ? a : b);
    return Asmax.Asb;
  }

  Asfi() {
    const calculStabiliteInterne = this.calculStabiliteInterne();
    const Asmax = calculStabiliteInterne.reduce((a, b) => a.Asfi > b.Asfi ? a : b);
    return Asmax.Asfi;
  }

  Asfsup() {
    const calculStabiliteInterne = this.calculStabiliteInterne();
    const Asmax = calculStabiliteInterne.reduce((a, b) => a.Asfsup > b.Asfsup ? a : b);
    return Asmax.Asfsup;
  }

  bilanStabiliteInterne() {
    const Asc = this.Asc();
    const Asb = this.Asb();
    const Asfi = this.Asfi();
    const Asfsup = this.Asfsup();

    return {
      id: "bilanStabiliteInterne",
      name: "bilanStabiliteInterne",
      label: "Ferraillage du mur de soutenement",
      value: [
        // { name: "Armature courante voile (cm2/ml)", value: Asc },
        { name: "As,base voile (cm2/ml)", value: Asb },
        { name: "As,fondation inférieure (cm2/ml)", value: Asfi },
        { name: "As,fondation supérieure (cm2/ml)", value: Asfsup },
      ],
      description: "Bilan stabilite interne",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }


};

export default InternalStability;