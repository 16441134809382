import React, { useRef, useCallback } from 'react';
import Dropzone from "react-dropzone";
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Input,
  Button
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import TableElem from '../../../../Components/TableElem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DataTable from '../../Components/DataTable/DataTable';
import Graphe from '../Graphe/Graphe';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonPrimary: { 
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  buttonSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }
}));


const Resultats = ({
  state,
  setState
}) => {
  const dropzoneRef = useRef(null);


  const classes = useStyles();

  const handleChangeProjectData = (prop) => (event) => {
    const newData = {
      ...state.data,
      [prop]: {
        ...state.data[prop],
        value: event.target.value
      }
    };

    const updatedState = {
      ...state,
      data: {
        ...state.data,
        ...newData
      }
    }

    setState(updatedState);
  };



  const handleExportStateAsJsonFile = async () => {
    const {
      data
    } = state;
    const fileName = `${data.projet.value}_${data.version.value}`;
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleImportStateAsFile = useCallback((files) => {
    const fileReader = new FileReader();
    fileReader.readAsText(files[0], "UTF-8");
    fileReader.onload = e => {
      const newData = JSON.parse(e.target.result);

      const updatedState = {
        ...state,
        data: {
          ...state.data,
          ...newData
        }
      }

      setState(updatedState);
    };
  }, [])

  const handleClickOpen = () => {
    dropzoneRef.current.open();
  };


  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="div">
          {`Stabilité externe`}
        </Typography>
      </Grid> */}
      <Grid item md={6}>
        <CardElem
          title="Projet"
          subtitle="Données du projet"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableElem
                columns={[]}
                data={state.data}
                ui={state.ui.projet}
                onChange={handleChangeProjectData}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <Button
                className={classes.buttonPrimary}
                variant="contained"
                // color="secondary"
                startIcon={<CloudUploadIcon />}
                onClick={handleClickOpen}
              >Importer</Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                className={classes.buttonPrimary}
                // color="secondary"
                startIcon={<CloudDownloadIcon />}
                onClick={handleExportStateAsJsonFile}
              >
                Télécharger
              </Button>
            </Grid>
          </Grid>
        </CardElem>
      </Grid>
      <Dropzone ref={dropzoneRef} onDrop={(e) => handleImportStateAsFile(e)}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} accept=".json" />
          </div>
        )}
      </Dropzone>
      <Grid item md={6} style={{ width: '100%' }}>
        <Graphe
          state={state}
        />
      </Grid>
    </Grid>
  )
}

export default Resultats;