import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Input,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import SelectElem from '../../../../Components/SelectElem';
import DataTable from '../../Components/DataTable/DataTable';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import TableElem from '../../../../Components/TableElem';
import NumberFormat from 'react-number-format';

import { RetainingWall } from '../../Utils/Calculations';

const Parametres = ({
  state,
  setState,
  handleChangeState
}) => {

  const handleChangeCombinaison = (e, index, key, loadCaseIndex) => {
    const loadCombinations = state.data.combinaisonsDeCharges.value;

    if (key === "loadCases") {
      const loadCases = loadCombinations[index].loadCases;
      const updateLoadCase = {
        ...loadCases[loadCaseIndex],
        value: Number(e.target.value)
      }
      const newLoadCases = loadCases.map((loadCase, i) => i === loadCaseIndex ? updateLoadCase : loadCase)

      const updateLoadCombinaison = {
        ...loadCombinations[index],
        "loadCases": [...newLoadCases]
      }

      loadCombinations.splice(index, 1, updateLoadCombinaison);
    } else {

      const updateLoadCombinaison = {
        ...loadCombinations[index],
        [key]: e.target.value
      }

      loadCombinations.splice(index, 1, updateLoadCombinaison);
    }

    const newData = {
      ...state.data,
      combinaisonsDeCharges: {
        ...state.data.combinaisonsDeCharges,
        value: [...loadCombinations]
      }
    };

    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }

    setState(updatedState);
  }

  const handleAddData = () => {
    const loadCombinations = [...state.data.combinaisonsDeCharges.value];
    const n = loadCombinations.length;
    const newData = {
      ...state.data,
      combinaisonsDeCharges: {
        ...state.data.combinaisonsDeCharges,
        value: [
          ...loadCombinations,
          {
            id: `ELS${n}`,
            name: `ELS${n}`,
            label: `ELS${n}`,
            type: "ELS",
            categorie: "caracteristique",
            loadCases: [
              { id: "G0", value: 1 },
              { id: "G1", value: 1 },
              { id: "Q", value: 1 },
              { id: "E", value: 1 },
            ]
          },
        ]
      }
    };

    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }

    setState(updatedState);

  }

  const handleRemoveData = (index) => {
    const loadCombinations = [...state.data.combinaisonsDeCharges.value];
    if (loadCombinations.length > 1) {
      const loadCombinations = [...state.data.combinaisonsDeCharges.value];
      const newLoadCombinations = loadCombinations.filter((item, i) => i !== index)

      const newData = {
        ...state.data,
        combinaisonsDeCharges: {
          ...state.data.combinaisonsDeCharges,
          value: [...newLoadCombinations]
        }
      };

      const calculatedData = new RetainingWall(newData).analysis();

      const updatedState = {
        ...state,
        data: {
          ...newData,
          ...calculatedData
        }
      }

      setState(updatedState);
    }
  }

  const handleRemoveAll = () => {
    const newData = {
      ...state.data,
      combinaisonsDeCharges: {
        ...state.data.combinaisonsDeCharges,
        value: [
          {
            id: `ELS0`,
            name: `ELS0`,
            label: `ELS0`,
            type: "ELS",
            categorie: "caracteristique",
            loadCases: [
              { id: "G0", value: 1 },
              { id: "G1", value: 1 },
              { id: "Q", value: 1 },
              { id: "E", value: 1 },
            ]
          },
        ]
      }
    };

    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }

    setState(updatedState);
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <CardElem
          title="Type de cas de charges"
          subtitle="Liste des type de cas de charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "type", label: "type" },
              { value: "description", label: "description" },
            ]}
            rows={state.data.casDeCharges.value}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={6}>
        <CardElem
          title="Type de combinaisons de charges"
          subtitle="Liste des type de combinaisons de charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "type", label: "type" },
              { value: "description", label: "description" },
            ]}
            rows={state.data.typeCategories.value}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Coefficients de sécurité"
          subtitle="Liste des coefficients de sécurité"
        >
          <TableElem
            columns={["nom", "valeur", "description"]}
            data={state.data}
            ui={state.ui.parameters.coefficients}
            onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Combinaisons de charges"
          subtitle="Liste des combinaisons de charges"
        >
          <div style={{ width: "100%", textAlign: "right", marginBottom: "1em" }}>
            <ButtonGroup variant="contained" color="white" aria-label="outlined primary button group">
              <IconButton
                aria-label="add"
                color="secondary"
                onClick={handleAddData}
              >
                <AddCircleIcon />
              </IconButton>
              <IconButton
                aria-label="add"
                color="secondary"
                onClick={handleRemoveAll}
              >
                <DeleteIcon />
              </IconButton>
            </ButtonGroup>
          </div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Categorie</TableCell>
                  {state.data.combinaisonsDeCharges.value[0].loadCases.map((loadCase, index) => (
                    <TableCell>{`${loadCase.id}`}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.combinaisonsDeCharges.value.map((combinaison, index) => (
                  <TableRow key={`${index}`}>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        style={{ border: 0 }}
                        value={combinaison['name']}
                        onChange={(e) => handleChangeCombinaison(e, index, "name")}
                        disableUnderline={true}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <SelectElem
                        type="text"
                        value={combinaison['type']}
                        list={state.data.typeCombinaisonsDeCharges.value}
                        onChange={(e) => handleChangeCombinaison(e, index, "type")}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <SelectElem
                        type="text"
                        value={combinaison['categorie']}
                        list={state.data.typeCategories.value.filter(categorie => categorie.type === combinaison['type'])}
                        onChange={(e) => handleChangeCombinaison(e, index, "categorie")}
                      />
                    </TableCell>
                    {combinaison.loadCases.map((loadCase, k) => (
                      <TableCell scope="row">
                        <NumberFormat
                          style={{ border: 0, color: loadCase['value'] === 0 ? 'grey' : 'blue', height: '100%', width: '100%', margin: 0 }}
                          type="text"
                          disableUnderline={true}
                          onChange={(e) => handleChangeCombinaison(e, index, "loadCases", k)}
                          value={loadCase['value']}
                        />
                        {/* <Input
                          type="text"
                          value={loadCase['value']}
                          onChange={(e) => handleChangeCombinaison(e, index, "loadCases", k)}
                          disableUnderline={true}
                          style={{ color: loadCase['value'] === 0 ? 'grey' : 'blue' }}
                        /> */}
                      </TableCell>
                    ))}
                    <TableCell scope="row">
                      <IconButton
                        aria-label="delete"
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveData(index)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardElem>
      </Grid>
    </Grid>
  )
}

export default Parametres;