import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import TableElem from '../../../../Components/TableElem';
import Graphe from '../Graphe/Graphe';

const Structure = ({
  state,
  handleChangeState
}) => {

  return (
    <Grid container spacing={3}>
      {/* <Grid item sm={12}>
        <Typography gutterBottom variant="h6" component="div">
          {`Géométrie du mur`}
        </Typography>
      </Grid> */}
      <Grid item md={6}>
        <CardElem
          title="Structure"
          subtitle="Géométrie du mur"
        >
          <TableElem
            columns={["nom", "valeur", "unitées", "description"]}
            data={state.data}
            ui={state.ui.structure.geometry}
            onChange={handleChangeState}
          />
        </CardElem>
        <CardElem
          title="Matériaux"
          subtitle="Informations sur les matériaux de la structure"
        >
          <TableElem
            columns={["nom", "valeur", "unitées", "description"]}
            data={state.data}
            ui={state.ui.structure.materials}
            onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={6} style={{ width: '100%' }}>
        <Graphe
          state={state}
        />
      </Grid>
    </Grid>
  )
}

export default Structure;