import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import DataTable from '../../Components/DataTable/DataTable';
import Graphe from '../Graphe/Graphe';

const StabiliteExterne = ({
  state,
  handleChangeState
}) => {
  return (
    <Grid container spacing={3}>
      {/* <Grid item sm={12}>
        <Typography gutterBottom variant="h6" component="div">
          {`Géométrie du mur`}
        </Typography>
      </Grid> */}
      <Grid item md={12}>
        <CardElem
          title="Poids Propres"
          subtitle="Calcul du poids propres de la structure et du sol"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "loadCase", label: "Cas de charge" },
              { value: "xg", label: "xg(m)" },
              { value: "zg", label: "zg(m)" },
              { value: "S", label: "S(m2)" },
              { value: "gamma", label: "gamma(T/m3)" },
              { value: "BLst", label: "BLst(m)" },
              { value: "V", label: "Vd(T)" },
              { value: "H", label: "Hd(T)" },
              { value: "M", label: "Md(T.m)" }
            ]}
            rows={state.data.poidsPropres}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Poussées"
          subtitle="Calcul des efforts de poussée du sol et des charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "loadCase", label: "Cas de charge" },
              { value: "V", label: "Vd(T)" },
              { value: "H", label: "Hd(T)" },
              { value: "M", label: "Md(T.m)" },
            ]}
            rows={state.data.poussees}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Butées"
          subtitle="Calcul des efforts de butées"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "loadCase", label: "Cas de charge" },
              { value: "V", label: "Vd(T)" },
              { value: "H", label: "Hd(T)" },
              { value: "M", label: "Md(T.m)" },
            ]}
            rows={state.data.butees}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Bilans des forces par cas de charges"
          subtitle="Calcul du bilan des forces par cas de charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "value", label: "Cas de charge" },
              { value: "V", label: "Vd(T)" },
              { value: "H", label: "Hd(T)" },
              { value: "M", label: "Md(T.m)" },
            ]}
            rows={state.data.bilanEffortsExternes}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Bilans des forces par combinaison de charges"
          subtitle="Calcul du bilan des forces par combinaison de charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "type", label: "type" },
              { value: "categorie", label: "categorie" },
              { value: "V", label: "Vd(T)" },
              { value: "H", label: "Hd(T)" },
              { value: "M", label: "Md(T.m)" },
              { value: "e", label: "e(m)" },
              { value: "Rhd", label: "Glissement: Rhd(T)" },
              { value: "Sgl", label: "Glissement: Sgl" },
              { value: "Sext", label: "Excentrement: ie" },
              { value: "SextLim", label: "Excentrement: ieLim" },
              { value: "coefExt", label: "Excentrement: Sext" },
              { value: "idelta", label: "Capacité portante: iδ" },
              { value: "qnet", label: "Contrainte nette: qnet(kPa)" },
              { value: "sigmaSol", label: "Capacité portante: σV;d(kPa)" },
              { value: "VdLim", label: "Capacité portante: VdLim(T/ml)" },
              { value: "Sbearing", label: "Capacité portante: Vd/VdLim" }
            ]}
            rows={state.data.calculStabiliteExterne}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
    </Grid>
  )
}

export default StabiliteExterne;