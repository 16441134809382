
class GeometryFunctions {
  constructor(data) {
    this.data = data;
  }

  getArea(arrayExt, arrayInt) {
    const secext = arrayExt;
    const secint = arrayInt;

    let areaExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const areai = 0.5 * (secext[i + 1].y - secext[i].y) * (secext[i + 1].x + secext[i].x);
      areaExt += areai;
    }
    let areaInt = 0;
    for (let i = 0; i < secint.length - 1; i += 1) {
      const areai = 0.5 * (secint[i + 1].y - secint[i].y) * (secint[i + 1].x + secint[i].x);
      areaInt += areai;
    }
    let area = areaExt - areaInt;
    //area = Math.round(area * 1000) / 1000;
    return area;
  }

  getPerimeter(arrayExt, arrayInt) {
    const secext = arrayExt;
    const secint = arrayInt;

    let pExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const pi = (((secext[i + 1].x - secext[i].x) ** 2) + ((secext[i + 1].y - secext[i].y) ** 2)) ** 0.5;
      pExt += pi;
    }
    let pInt = 0;
    for (let i = 0; i < secint.length - 1; i += 1) {
      const pi = (((secint[i + 1].x - secint[i].x) ** 2) + ((secint[i + 1].y - secint[i].y) ** 2)) ** 0.5;
      pInt += pi;
    }
    //pExt = Math.round(pExt * 1000) / 1000;
    //pInt = Math.round(pInt * 1000) / 1000;
    return {
      pExt,
      pInt
    };
  }

  getXg(arrayExt, arrayInt) {
    const secext = arrayExt;
    const secint = arrayInt;
    const area = this.getArea(arrayExt, arrayInt);

    let xgExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const xgi = (secext[i + 1].y - secext[i].y) * ((secext[i + 1].x ** 2) + secext[i + 1].x * secext[i].x + (secext[i].x ** 2));
      xgExt += xgi;
    }
    let xgInt = 0;
    for (let i = 0; i < secint.length - 1; i += 1) {
      const xgi = (secint[i + 1].y - secint[i].y) * ((secint[i + 1].x ** 2) + secint[i + 1].x * secint[i].x + (secint[i].x ** 2));
      xgInt += xgi;
    }
    let xg = 0;
    if (area !== 0) {
      xg = (xgExt - xgInt) / (6 * area);
    }
    //xg = Math.round(xg * 1000) / 1000;
    return xg;
  }

  getYg(arrayExt, arrayInt) {
    const secext = arrayExt;
    const secint = arrayInt;
    const area = this.getArea(arrayExt, arrayInt);

    let ygExt = 0;
    for (let i = 0; i < secext.length - 1; i += 1) {
      const ygi = (secext[i + 1].x - secext[i].x) * ((secext[i + 1].y ** 2) + secext[i + 1].y * secext[i].y + (secext[i].y ** 2));
      ygExt += ygi;
    }
    let ygInt = 0;
    for (let i = 0; i < secint.length - 1; i += 1) {
      const ygi = (secint[i + 1].x - secint[i].x) * ((secint[i + 1].y ** 2) + secint[i + 1].y * secint[i].y + (secint[i].y ** 2));
      ygInt += ygi;
    }
    let yg = 0;
    if (area !== 0) {
      yg = -(ygExt - ygInt) / (6 * area);
    }
    //yg = Math.round(yg * 1000) / 1000;
    return yg;
  }
}

export default GeometryFunctions;