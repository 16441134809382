
class Eurocode2 {
  constructor(data) {
    this.data = data;
    this.alphacc = 0.85;
    this.gammacc = 1.5;
    this.gammas = 1.15;
    this.Ec = 31500;
    this.Es = 210000;
    this.epsilonuk = 0.0075;
  }

  fcd({ fck, alphacc, gammacc }) {
    return {
      name: "fcd",
      label: "fcd",
      value: alphacc * fck / gammacc,
      unit: { value: "MPa", label: "MPa" },
      description: "design compressive strength of concrete",
      reference: "EN1992-1-1 §3.1.6"
    }
  }

  fctm({ fck }) {
    let value = 0;
    if (fck > 50) {
      value = 2.12 * Math.log(1 + (fck + 8) / 10);
    } else {
      value = 0.3 * fck ** (2 / 3);
    }
    return {
      name: "fctm",
      label: "fctm",
      value,
      unit: { value: "MPa", label: "MPa" },
      description: "Mean tensile strength",
      reference: "EN1992-1-1 Table 3.1"
    }
  }

  fyd({ fyk, gammas }) {
    return {
      name: "fyd",
      label: "fyd",
      value: fyk / gammas,
      unit: { value: "MPa", label: "MPa" },
      description: "design yield strength of reinforcement steel",
      reference: "EN1992-1-1 §3.2"
    }
  }


  d({ h, c }) {
    return {
      name: "d",
      label: "d",
      value: h - c,
      unit: { value: "m", label: "m" },
      description: "",
      reference: ""
    }
  }

  mud({ Ned, Med, b, c, h, fcd, fyd, Assup }) {
    const d = h - c;
    let Meq = (Med + Ned * (d - h / 2)) / 1000;

    let value = 0;
    if (b > 0 && d > 0 && fcd > 0) {
      // value = (Meq - Assup / 10000 * fyd * (d - 0.1 * h)) / (fcd * b * d ** 2);
      value = (Meq) / (fcd * b * d ** 2);
    }
    return {
      name: "mud",
      label: "mud",
      value,
      unit: { value: "", label: "" },
      description: "Moment reduit",
      reference: ""
    }
  }

  alphau({ mud }) {
    let value = 1.25;
    if (mud < 1 / 2) {
      value = 1.2 * (1 - (1 - 2 * mud) ** (0.5))
    }
    return {
      name: "ksi",
      label: "ksi",
      value,
      unit: { value: "", label: "" },
      description: "Hauteur de l'axe neutre",
      reference: ""
    }
  }

  zu({ d, alphau }) {
    return {
      name: "zu",
      label: "zu",
      value: d * alphau,
      unit: { value: "m", label: "m" },
      description: "Bras de leviere",
      reference: ""
    }
  }

  // Allongement de l'acier tendu
  epsilons({ ksi }) {

  }

  epsilonud({ epsilonuk }) {
    return {
      name: "epsilonud",
      label: "εud",
      value: 0.9 * epsilonuk,
      unit: { value: "", label: "" },
      description: "Design value of reinforcement ultimate strain",
      reference: "EN1992-1-1 §3.2.7(2)"
    }
  }


  Assup({ Ned, Med, b, d, h, fcd, fyd }) {
    let Meq = Med + Ned * (d - h / 2) / 1000;

    let value = Math.max(0, (Meq - 0.37 * b * d ** (2) * fcd) / (fyd * (d - 0.1 * h)))
    return {
      name: "Assup",
      label: "As,sup",
      value,
      unit: { value: "cm2", label: "cm²" },
      description: "Surface d'armatures supérieures",
      reference: ""
    }

  }


  Asinf({ Ned, zu, b, fcd, fyd, Assup }) {
    let value = (0.8 * zu * b * fcd - Ned / 1000) * 10000 / fyd + Assup;

    return {
      name: "Asinf",
      label: "As,inf",
      value,
      unit: { value: "cm2", label: "cm²" },
      description: "Surface d'armatures inférieures",
      reference: ""
    }
  }



  Assupmin({ b, d, fctm, fyk }) {
    let value = Math.max(0.26 * b * d * fctm / fyk, 0.0013 * b * d) * 10000
    return {
      name: "Assupmin",
      label: "Asmin,sup",
      value,
      unit: { value: "cm2", label: "cm²" },
      description: "Surface d'armatures supérieures minimales",
      reference: ""
    }
  }


  Asinfmin({ b, d, fctm, fyk }) {
    let value = Math.max(0.26 * b * d * fctm / fyk, 0.0013 * b * d) / (0.01) **2
    return {
      name: "Asinfmin",
      label: "Asmin,inf",
      value,
      unit: { value: "cm2", label: "cm²" },
      description: "Surface d'armatures inférieures minimales",
      reference: ""
    }
  }


  sectionRectangulaireFlexionCompression({
    b,
    c,
    h,
    fck,
    Ecm,
    alphacc,
    gammacc,
    fyk,
    Es,
    gammas,
    Ned,
    Med
  }) {
    const fcd = this.fcd({ fck, alphacc, gammacc }).value;
    const fctm = this.fctm({ fck }).value;
    const fyd = this.fyd({ fyk, gammas }).value;
    const d = this.d({ h, c }).value;


    const Asinfmin = this.Asinfmin({ b, d, fctm, fyk }).value;
    const Assupmin = this.Assupmin({ b, d, fctm, fyk }).value;

    const Assup = this.Assup({ Ned, Med, b, d, h, fcd, fyd }).value;

    const mud = this.mud({ Ned, Med, b, c, h, fcd, fyd, Assup }).value;
    const alphau = this.alphau({ mud }).value;
    const zu = this.zu({ d, alphau }).value;
    
    const Asinf = this.Asinf({ Ned, zu, b, fcd, fyd, Assup: 0 }).value;
    return {
      Asinf:  Math.max(Asinf, Asinfmin),
      Assup: Math.max(Assup, Assupmin),
      mud,
      zu
      // Asinf: Math.max(Asinf, 0),
      // Assup: Math.max(Assup, 0),
    }
  }
}

export default Eurocode2;