import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Button
} from 'reactstrap';


const DataTable = ({
  columns,
  rows,
  onChange
}) => {
  // const [columns, setColumns] = useState(["nom", "valeur", "unitées", "description"]);
  // const [rows, setRows] = useState([]);
  // useEffect(() => {
  //   setRows(ui)
  // }, []);


  return (
    <div>
      <Table responsive>
        <thead>
          <tr key={0}>
            {columns.map((column, index) => (
              <td key={index}>{column.label}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index * columns.length} style={{ borderTop: "solid 1px #E8E8E8", borderBottom: "solid 1px #E8E8E8" }}>
              {columns.map((item, i) => (
                <td key={index * columns.length + i + 1}>{`${row[item.value]}`}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default DataTable;