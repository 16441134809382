class Standards {
  constructor() {

  }

  getSafetyFactors(selectedStandard) {

  }

  getLoadCases(selectedStandard) {

  }

  getLoadCombinations(selectedStandard) {

  }

};

export default Standards;