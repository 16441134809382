const chartOptions = (data) => {

  let xmin = 0;
  let xmax = 0;
  let ymin = 0;
  let ymax = 0;



  data.value.structure.map((item, index) => {
    item.value.map(coordinate => {
      xmin = Math.min(coordinate.x, xmin);
      xmax = Math.max(coordinate.x, xmax);
      ymin = Math.min(coordinate.y, ymin);
      ymax = Math.max(coordinate.y, ymax);
    })
  })

  data.value.soil.map((item, index) => {
    item.value.map(coordinate => {
      xmin = Math.min(coordinate.x, xmin);
      xmax = Math.max(coordinate.x, xmax);
      ymin = Math.min(coordinate.y, ymin);
      ymax = Math.max(coordinate.y, ymax);
    })
  })

  data.value.water.map((item, index) => {
    item.value.map(coordinate => {
      xmin = Math.min(coordinate.x, xmin);
      xmax = Math.max(coordinate.x, xmax);
      ymin = Math.min(coordinate.y, ymin);
      ymax = Math.max(coordinate.y, ymax);
    })
  })

  const deltaX = xmax - xmin;
  const deltaY = ymax - ymin;

  const deltaMax = Math.max(deltaX, deltaY);
  xmax = xmin + deltaMax;
  ymax = ymin + deltaMax;

  return {
    // maintainAspectRatio: false,
    // responsive: true,
    showLines: true,
    animation: false,
    legend: {
      display: true
    },
    plugins: {
      legend: false // Hide legend
    },
    tooltips: {
      callbacks: {
        label: (t) => {
          const numx = t.xLabel.toFixed(3);
          const numy = t.yLabel.toFixed(3);
          const label = `X: ${numx} Y : ${numy}`;
          return label;
        }
      }
    },
    elements: {
      point: { radius: 1 }
    },
    scales: {
      xAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          // min: -1,
          // max: 3
          min: xmin - 0.5,
          max: xmax + 0.5,
          // stepSize: deltaMax
        },
        scaleLabel: {
          display: true,
          labelString: `${data.axisName.x}(${data.unit.x})`
        }
      }],
      yAxes: [{
        type: 'linear',
        position: 'bottom',
        ticks: {
          // min: -1,
          // max: 3
          min: ymin - 0.5,
          max: ymax + 0.5,
          // stepSize: deltaMax
        },
        scaleLabel: {
          display: true,
          labelString: `${data.axisName.y}(${data.unit.y})`
        }
      }]
    },
  };
};

export default chartOptions;
