import General from './General';
import Documentation from './Documentation';
import Standards from './Standards';
import ExternalStability from './ExternalStability';
import InternalStability from './InternalStability';

class RetainingWall extends General {
  constructor(data) {
    super(data);
    this.data = data;
    this.documentation = new Documentation();
    this.standards = new Standards('Eurocodes');
    this.externalStability = new ExternalStability(this.data);
    this.internalStability = new InternalStability(this.data, this.standards);
    // this.units = new Units();
    // this.standards = new Standards();
  }



  analysis() {
    const color = 'grey';
    return {
      Ls: {
        ...this.data.Ls,
        value: this.externalStability.Ls(),
        color
      },
      beta: {
        ...this.data.beta,
        value: this.externalStability.beta(),
        color
      },
      delta: {
        ...this.data.delta,
        value: this.externalStability.delta(),
        color
      },
      Ka: {
        ...this.data.Ka,
        value: this.externalStability.Ka(),
        color
      },
      Kp: {
        ...this.data.Kp,
        value: this.externalStability.Kp(),
        color
      },

      kp: {
        ...this.data.kp,
        value: this.externalStability.kp(),
        color
      },
      SML: this.externalStability.SML(),
      BEC: this.externalStability.BEC(),
      M: this.externalStability.M(),
      FG: this.externalStability.FG(),
      FD: this.externalStability.FD(),
      RG: this.externalStability.RG(),
      RD: this.externalStability.RD(),
      RDD: this.externalStability.RDD(),
      HEG: this.externalStability.HEG(),
      HED: this.externalStability.HED(),
      QD: this.externalStability.QD(),
      poidsPropres: this.externalStability.poidsPropres(),
      pousseesTerrain: this.externalStability.pousseesTerrain(),
      poussees: this.externalStability.poussees(),
      butees: this.externalStability.butees(),
      bilanEffortsExternes: this.externalStability.bilanEffortsExternes(),
      calculStabiliteExterne: this.externalStability.calculStabiliteExterne(),
      verificationGlissement: this.externalStability.verificationGlissement(),
      verificationRenversement: this.externalStability.verificationRenversement(),
      verificationExcentrement: this.externalStability.verificationExcentrement(),
      // verificationContrainteDuSol: this.externalStability.verificationContrainteDuSol(),
      verificationSurfaceComprimee: this.externalStability.verificationSurfaceComprimee(),
      verificationCapacitePortante: this.externalStability.verificationCapacitePortante(),
      bilanStabiliteExterne: {
        ...this.externalStability.bilanStabiliteExterne()
      },
      recapitulatifSollicitations: this.internalStability.recapitulatifSollicitations(),
      calculStabiliteInterne: this.internalStability.calculStabiliteInterne(),
      bilanStabiliteInterne: {
        ...this.internalStability.bilanStabiliteInterne()
      },
      HdMax: { ...this.externalStability.HdMax() },
      RhdMax: { ...this.externalStability.RhdMax() },
      Med: { ...this.externalStability.Med() },
      Mrd: { ...this.externalStability.Mrd() },
      sigmaCrit: { ...this.externalStability.sigmaCrit() },
      sigmaLimCrit: { ...this.externalStability.sigmaLimCrit() },
      surfaceCrit: { ...this.externalStability.surfaceCrit() },
      surfaceLimCrit: { ...this.externalStability.surfaceLimCrit() },
    }
  }
}

export default RetainingWall;