const initialState = {
  data: {
    // DESCRIPTION DU PROJET
    projet: {
      id: "projet",
      name: "projet",
      label: "projet",
      value: "NomDuProjet",
      description: "Nom du projet",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    version: {
      id: "version",
      name: "version",
      label: "version",
      value: "0",
      description: "Version du projet",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    // BILAN DU PROJET
    bilanStabiliteExterne: {
      id: "bilanStabiliteExterne",
      name: "bilanStabiliteExterne",
      label: "Bilan stabilite externe",
      value: [],
      description: "Bilan stabilite externe",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    bilanStabiliteInterne: {
      id: "bilanStabiliteInterne",
      name: "bilanStabiliteInterne",
      label: "Bilan stabilite interne",
      value: [],
      description: "Bilan stabilite interne",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    // GEOMETRIE STRUCTURE
    dav: {
      id: "dav",
      name: "dav",
      label: "dav",
      value: 0.2,
      description: "Débord de semelle aval",
      unit: { value: 'm', label: 'm' },
      standard: "",
      reference: ""
    },
    fav: {
      id: "fav",
      name: "fav",
      label: "fav",
      value: 0,
      description: "Fruit parement face aval",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    ep: {
      id: "ep",
      name: "ep",
      label: "ep",
      value: 0.2,
      description: "Epaisseur parement",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    fam: {
      id: "fam",
      name: "fam",
      label: "fam",
      value: 0,
      description: "Fruit parement face amont",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    dam: {
      id: "dam",
      name: "dam",
      label: "dam",
      value: 1.25,
      description: "Débord de semelle amont",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    zp: {
      id: "zp",
      name: "zp",
      label: "zp",
      value: 2.2,
      description: "Elévation du sommet du parement",
      unit: { name: 'm', text: 'm NGF' },
      standard: "",
      reference: ""
    },
    Hp: {
      id: "Hp",
      name: "Hp",
      label: "Hp",
      value: 2.5,
      description: "Hauteur du parement",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    es: {
      id: "es",
      name: "es",
      label: "es",
      value: 0.30,
      description: "Epaisseur de semelle",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    Hb: {
      id: "Hb",
      name: "Hb",
      label: "Hb",
      value: 0,
      description: "Hauteur de bêche",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    Lb: {
      id: "Lb",
      name: "Lb",
      label: "Lb",
      value: 0,
      description: "Largeur de bêche",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    xbec: {
      id: "xbec",
      name: "xbec",
      label: "xbec",
      value: 1,
      description: "Distance de la bêche par rapport à O",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    Ls: {
      id: "Ls",
      name: "Ls",
      label: "Ls",
      value: 1.75,
      description: "Largeur total de la semelle",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    SML: {
      label: "SML",
      value: [{ x: 0, y: 0 }]
    },
    BEC: {
      label: "BEC",
      value: [{ x: 0, y: 0 }]
    },
    M: {
      label: "M",
      value: [{ x: 0, y: 0 }]
    },
    FG: {
      label: "FG",
      value: [{ x: 0, y: 0 }]
    },
    FD: {
      label: "FD",
      value: [{ x: 0, y: 0 }]
    },
    // GEOMETRIE SOL
    Ht: {
      id: 'Ht',
      name: 'Ht',
      label: 'Ht',
      value: 2.5,
      description: "Hauteur du terrain",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    He1: {
      id: 'He1',
      name: 'He1',
      label: 'He1',
      value: 0,
      description: "Niveau d'eau amont",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    Aam: {
      id: 'Aam',
      name: 'Aam',
      label: 'Aam',
      value: 2,
      description: "Plateau horizontal",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    Bam: {
      id: 'Bam',
      name: 'Bam',
      label: 'Bam',
      value: 3,
      description: "Replat sur talus",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    alpha: {
      id: 'alpha',
      name: 'alpha',
      label: 'α',
      value: 0,
      description: "Talus incliné",
      unit: { value: "deg", label: "°" },
      standard: "",
      reference: ""
    },
    beta: {
      id: 'beta',
      name: 'beta',
      label: 'β',
      value: 0,
      description: "Angle d'inclinaison de l'écran",
      unit: { value: "deg", label: "°" },
      standard: "",
      reference: ""
    },
    deltaOnPhi: {
      id: 'deltaOnPhi',
      name: 'deltaOnPhi',
      label: 'δ/ϕ',
      value: 0,
      description: "Rapport d'obliquité des contraintes sur l'angle de frottement",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    delta: {
      id: 'delta',
      name: 'delta',
      label: 'δ',
      value: 0,
      description: "Angle de frottement sol / écran",
      unit: { value: "deg", label: "°" },
      standard: "",
      reference: ""
    },
    Ka: {
      id: 'Ka',
      name: 'Ka',
      label: 'Ka',
      value: 0.333,
      description: "Coefficient de poussée: formule de Coulomb",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    Kp: {
      id: 'Kp',
      name: 'Kp',
      label: 'Kp',
      value: 3,
      description: "Coefficient de butée: formule de Coulomb",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    Dt: {
      id: "Dt",
      name: "Dt",
      label: "D",
      value: 0.5,
      description: "Fiche du mur",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    He2: {
      id: "He2",
      name: "He2",
      label: "He2",
      value: 0,
      description: "Niveau d'eau aval",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    RG: {
      label: "RG",
      value: [{ x: 0, y: 0 }]
    },
    RD: {
      label: "RD",
      value: [{ x: 0, y: 0 }]
    },
    RDD: {
      label: "RDD",
      value: [{ x: 0, y: 0 }]
    },
    HEG: {
      label: "HEG",
      value: [{ x: 0, y: 0 }]
    },
    HED: {
      label: "HED",
      value: [{ x: 0, y: 0 }]
    },
    // MATERIAUX STRUCTURE
    gammac: {
      id: "gammac",
      name: "gammac",
      label: "ρc",
      value: 2.4,
      description: "Masse volumique du parement et semelle",
      unit: { value: "tOnM3", label: "t/m³" },
      standard: "",
      reference: ""
    },
    alphacc: {
      id: "alphacc",
      name: "alphacc",
      label: "αc",
      value: 0.85,
      description: "Coefficient tenant compte des effets à long terme sur la résistance à la compression et des effets défavorables résultant de la manière dont la charge est appliquée",
      unit: { value: "", label: "" },
      standard: "EN1992-1-1",
      reference: "3.1.6(1)"
    },
    gammacc: {
      id: "gammacc",
      name: "gammacc",
      label: "γc",
      value: 1.5,
      description: "Coefficient partiel relatif au béton",
      unit: { value: "", label: "" },
      standard: "EN1992-1-1",
      reference: "2.4.2.4(1)"
    },
    fck: {
      id: "fck",
      name: "fck",
      label: "fck",
      value: 25,
      description: "Résistance caractéristiques à la compression du béton",
      unit: { value: "MPa", label: "MPa" },
      standard: "EN1992-1-1",
      reference: ""
    },
    fyk: {
      id: "fyk",
      name: "fyk",
      label: "fyk",
      value: 500,
      description: "Limite élastique de l'acier",
      unit: { value: "MPa", label: "MPa" },
      standard: "EN1992-1-1",
      reference: ""
    },
    gammas: {
      id: "gammas",
      name: "gammas",
      label: "γs",
      value: 1.15,
      description: "Coefficient partiel relatif à l'acier",
      unit: { value: "", label: "" },
      standard: "EN1992-1-1",
      reference: "2.4.2.4(1)"
    },
    c: {
      id: "c",
      name: "c",
      label: "c",
      value: 0.05,
      description: "Enrobage des armatures",
      unit: { value: "m", label: "m" },
      standard: "",
      reference: ""
    },
    // MATERIAUX SOL
    gammar: {
      id: "gammar",
      name: "gammar",
      label: "ρr",
      value: 1.8,
      description: "Masse volumique remblai amont et aval",
      unit: { value: "tOnM3", label: "t/m³" },
      standard: "",
      reference: ""
    },
    phi: {
      id: 'phi',
      name: 'phi',
      label: 'ϕ',
      value: 30,
      description: "Angle frottement interne du sol",
      unit: { value: "deg", label: "°" },
      standard: "",
      reference: ""
    },
    deltaAk: {
      id: 'deltaAk',
      name: 'deltaAk',
      label: 'δak',
      value: 30,
      description: "Valeur caractéristique de l’angle de frottement à l’interface en la base de la fondation et le terrain",
      unit: { value: "deg", label: "°" },
      standard: "",
      reference: ""
    },
    sigmaElsSol: {
      id: "sigmaElsSol",
      name: "sigmaElsSol",
      label: "ple",
      value: 1000,
      description: "Pression limite équivalente du sol",
      unit: { value: "kPa", label: "kPa" },
      standard: "",
      reference: ""
    },
    gammae: {
      id: "gammae",
      name: "gammae",
      label: "ρe",
      value: 1,
      description: "Masse volumique de l'eau",
      unit: { value: "tOnM3", label: "t/m³" },
      standard: "",
      reference: ""
    },

    kp: {
      id: "kp",
      name: "kp",
      label: "kp",
      value: 1.14,
      description: "Facteur de portance pressiométrique",
      unit: { value: "", label: "" },
      standard: "",
      reference: "",
      color :'black'
    },



    typeDeSol: {
      id: "typeDeSol",
      name: "typeDeSol",
      label: "Type de sol",
      value: "solFrottant",
      list: [
        {
          id: "solCoherent",
          name: "solCoherent",
          label: "Sol cohérents",
          description: "sol cohérents (argiles, limons, craies, marnes et roches)",
          value: "solCoherent"
        },
        {
          id: "solFrottant",
          name: "solFrottant",
          label: "Sol frottants",
          description: "sol frottants (sables et graves)",
          value: "solFrottant"
        }
      ]
    },
    // CHARGES
    QD: {
      label: "QD",
      value: [
        {
          label: "1",
          value: [{ x: 0, y: 0 }]
        }
      ]
    },
    chargesSurTeteMur: {
      id: "chargesSurTeteMur",
      name: "chargesSurTeteMur",
      label: "Charges sur Tête de Mur",
      description: "Charges appliquées sur la tête du mur de soutenement",
      value: [
        {
          id: "T1",
          name: "T1",
          label: "T1",
          type: "ponctuelle",
          loadCase: "Q",
          description: "Charge d'exploitation appliquée en tête de mur",
          V: 1.7,
          H: 0,
          M: 0
        },
        {
          id: "T2",
          name: "T2",
          label: "T2",
          type: "ponctuelle",
          loadCase: "G0",
          description: "Charge permanente appliquée en tête de mur",
          V: 1.7,
          H: 0,
          M: 0
        },
      ]
    },
    chargesSurRemblai: {
      id: "chargesSurRemblai",
      name: "chargesSurRemblai",
      label: "Charges sur remblai",
      description: "Charges appliquées sur le remblai",
      value: [
        {
          id: "S1",
          name: "S1",
          label: "S1",
          type: "surfacique",
          loadCase: "Q",
          description: "Charge surfacique avec une valeur Qi à une distance xi du parement sur le remblai amont et une valeur Qj à une distance xj du parement",
          xi: 1,
          xj: 5,
          Qi: 1,
          Qj: 0.5
        },
        {
          id: "L1",
          name: "L1",
          label: "L1",
          type: "lineaire",
          loadCase: "Q",
          description: "Charge linéaire appliquée à une distance xi du parement sur le remblai amont avec une valeur Qi",
          xi: 3,
          xj: 0,
          Qi: 1,
          Qj: 0
        },
        {
          id: "P1",
          name: "P1",
          label: "P1",
          type: "ponctuelle",
          loadCase: "Q",
          description: "Charge ponctuelle appliquée à une distance xi du parement sur le remblai amont avec une valeur Qi",
          xi: 3,
          xj: 0,
          Qi: 1,
          Qj: 0
        },
      ]
    },
    typeEfforts: {
      id: "typeEfforts",
      name: "typeEfforts",
      label: "Type d'efforts",
      description: "Différents types d'efforts applicables",
      value: [
        { value: "ponctuelle", label: "ponctuelle" },
        { value: "lineaire", label: "linéaire" },
        { value: "surfacique", label: "surfacique" },
      ]
    },
    typeCasDeCharges: {
      id: "typeCasDeCharges",
      name: "typeCasDeCharges",
      label: "Type de cas de charges",
      description: "Différents types de cas de charges applicables",
      value: [
        { value: "permanente", label: "permanente" },
        { value: "exploitation", label: "exploitation" },
        { value: "hydraulique", label: "hydraulique" },
        { value: "neige", label: "neige" },
        { value: "vent", label: "vent" },
        { value: "temperature", label: "temperature" },
        { value: "sismique", label: "sismique" },
      ]
    },
    typeCombinaisonsDeCharges: {
      id: "typeCasCombinaisonsDeCharges",
      name: "typeCasCombinaisonsDeCharges",
      label: "Type de combinaison de charges",
      description: "Différents types de combinaisons de charges applicables",
      value: [
        { value: "ELS", label: "ELS" },
        { value: "ELU", label: "ELU" }
      ]
    },
    typeSituations: {
      id: "typeSituations",
      name: "typeSituations",
      label: "Type de situations",
      description: "Différents types de situations",
      value: [
        { value: "durable", label: "durable" },
        { value: "transitoire", label: "transitoire" },
        { value: "accidentelle", label: "accidentelle" },
        { value: "sismique", label: "sismique" }
      ]
    },
    typeCategories: {
      id: "typeCategories",
      name: "typeCategories",
      label: "Type de catégories",
      description: "Différents types de catégories",
      value: [
        { value: "caracteristique", type: "ELS", label: "Caractéristiques", description: "Combinaisons aux états limites de service - combinaisons caractéristiques (équivalent de ELS rare);" },
        { value: "frequente", type: "ELS", label: "Fréquentes", description: "Combinaisons aux états limites de service - combinaisons fréquentes;" },
        { value: "quasiPermanente", type: "ELS", label: "Quasi-permanentes", description: "Combinaisons aux états limites de service - combinaisons quasi-permanentes" },
        { value: "EQU", type: "ELU", label: "EQU", description: "Combinaisons aux états limites ultimes - Perte d'équilibre statique de la structure ou d'une partie quelconque de celle-ci, considérée comme un corps rigide, lorsque des variations mineures de la valeur ou de la distribution spatiale d'actions d'une source unique sont significatives et les résistances des matériaux de construction ou du sol ne sont généralement pas dominantes ;" },
        { value: "STR", type: "ELU", label: "STR", description: "Combinaisons aux états limites ultimes - Défaillance interne ou déformation excessive de la structure ou d'éléments structuraux, y compris semelles, pieux, murs de soubassement, etc., lorsque la résistance des matériaux de construction de la structure domine" },
        { value: "GEO", type: "ELU", label: "GEO", description: "Combinaisons aux états limites ultimes - Défaillance ou déformation excessive du sol, lorsque les résistances du sol ou de la roche sont significatives pour la résistance ; " },
        { value: "FAT", type: "ELU", label: "FAT", description: "Combinaisons aux états limites ultimes - Défaillance de la structure ou d'éléments structuraux due à la fatigue. " }
      ]
    },
    casDeCharges: {
      id: "casDeCharges",
      name: "casDeCharges",
      label: "Cas de charges",
      description: "Liste des cas de charges",
      value: [
        {
          id: "G0",
          name: "G0",
          label: "G0",
          type: "permanent",
          description: "actions permanentes dites 'favorables': poids propres des éléments (mur, remblai,...), butée des terres et charge G en tête de mur",
          value: "G0"
        },
        {
          id: "G1",
          name: "G1",
          label: "G1",
          type: "permanent",
          description: "action permanentes dites 'défavorables': poussée des terres",
          value: "G1"
        },
        {
          id: "Q",
          name: "Q",
          label: "Q",
          type: "exploitation",
          description: "poussées de la surcharge et charge Q en tête de mur",
          value: "Q"
        },
        {
          id: "E",
          name: "E",
          label: "E",
          type: "hydraulique",
          description: "poussée hydrostatique de l'eau",
          value: "E"
        },
      ]
    },
    combinaisonsDeCharges: {
      id: "combinaisonsDeCharges",
      name: "combinaisonsDeCharges",
      label: "Combinaisons de charges",
      description: "Liste des combinaisons de charges",
      value: [
        {
          id: "ELS0",
          name: "ELS0",
          label: "ELS0",
          type: "ELS",
          categorie: "quasiPermanente",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "ELS1",
          name: "ELS1",
          label: "ELS1",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "ELS2",
          name: "ELS2",
          label: "ELS2",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 1 },
          ]
        },
        {
          id: "ELS3",
          name: "ELS3",
          label: "ELS3",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1 },
            { id: "E", value: 1 },
          ]
        },
        {
          id: "ELS4",
          name: "ELS4",
          label: "ELS4",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "ELS5",
          name: "ELS5",
          label: "ELS5",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1 },
            { id: "Q", value: 1 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "ELS6",
          name: "ELS6",
          label: "ELS6",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1 },
            { id: "Q", value: 0 },
            { id: "E", value: 1 },
          ]
        },
        {
          id: "ELS7",
          name: "ELS7",
          label: "ELS7",
          type: "ELS",
          categorie: "caracteristique",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1 },
            { id: "Q", value: 1 },
            { id: "E", value: 1 },
          ]
        },
        {
          id: "EQU1",
          name: "EQU1",
          label: "EQU1",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU2",
          name: "EQU2",
          label: "EQU2",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU3",
          name: "EQU3",
          label: "EQU3",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU4",
          name: "EQU4",
          label: "EQU4",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU5",
          name: "EQU5",
          label: "EQU5",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU6",
          name: "EQU6",
          label: "EQU6",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU7",
          name: "EQU7",
          label: "EQU7",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU8",
          name: "EQU8",
          label: "EQU8",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 0.9 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU9",
          name: "EQU9",
          label: "EQU9",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU10",
          name: "EQU10",
          label: "EQU10",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU11",
          name: "EQU11",
          label: "EQU11",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU12",
          name: "EQU12",
          label: "EQU12",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU13",
          name: "EQU13",
          label: "EQU13",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU14",
          name: "EQU14",
          label: "EQU14",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "EQU15",
          name: "EQU15",
          label: "EQU15",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "EQU16",
          name: "EQU16",
          label: "EQU16",
          type: "ELU",
          categorie: "EQU",
          loadCases: [
            { id: "G0", value: 1.1 },
            { id: "G1", value: 1.1 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR1",
          name: "STR1",
          label: "STR1",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR2",
          name: "STR2",
          label: "STR2",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR3",
          name: "STR3",
          label: "STR3",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR4",
          name: "STR4",
          label: "STR4",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR5",
          name: "STR5",
          label: "STR5",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR6",
          name: "STR6",
          label: "STR6",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR7",
          name: "STR7",
          label: "STR7",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR8",
          name: "STR8",
          label: "STR8",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR9",
          name: "STR9",
          label: "STR9",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR10",
          name: "STR10",
          label: "STR10",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR11",
          name: "STR11",
          label: "STR11",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 0 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR12",
          name: "STR12",
          label: "STR12",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 0 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR13",
          name: "STR13",
          label: "STR13",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 0 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR14",
          name: "STR14",
          label: "STR14",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 0 },
          ]
        },
        {
          id: "STR15",
          name: "STR15",
          label: "STR15",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 0 },
            { id: "E", value: 1.2 },
          ]
        },
        {
          id: "STR16",
          name: "STR16",
          label: "STR16",
          type: "ELU",
          categorie: "STR",
          loadCases: [
            { id: "G0", value: 1.35 },
            { id: "G1", value: 1.35 },
            { id: "Q", value: 1.5 },
            { id: "E", value: 1.2 },
          ]
        },
      ]
    },
    Hed: {
      id: "Hed",
      name: "Hed",
      label: "Hed (T)",
      value: 0,
      description: "Effort horizontal sollicitant",
      unit: { value: 'T', label: 'T' },
      standard: "",
      reference: ""
    },
    Hrd: {
      id: "Hrd",
      name: "Hrd",
      label: "Hrd (T)",
      value: 0,
      description: "Effort horizontal resistant",
      unit: { value: 'T', label: 'T' },
      standard: "",
      reference: ""
    },
    gammaGL: {
      id: "gammaGL",
      name: "gammaGL",
      label: "𝛾Rdh",
      value: 0.9,
      description: "Coefficient de sécurité au glissement",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    gammaRdh: {
      id: "gammaRdh",
      name: "gammaRdh",
      label: "𝛾Rdh",
      value: 0.9,
      description: "coefficient de modèle lié à l’estimation de la résistance ultime au glissement soit 0,9",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    gammaRh: {
      id: "gammaRh",
      name: "gammaRh",
      label: "𝛾Rh",
      value: 1.1,
      description: "facteur partiel pour la résistance au glissement soit 1,1 pour les ELU fondamentaux et 1 pour les ELU Accidentels",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    gammaRp: {
      id: "gammaRp",
      name: "gammaRp",
      label: "𝛾Rp",
      value: 1.4,
      description: "facteur partiel dépendant du type de réaction mobilisée devant la semelle et du type d’état limite ultime",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    gammaRdvEls: {
      id: "gammaRdvEls",
      name: "gammaRdvEls",
      label: "𝛾Rdh,ELS",
      value: 2.3,
      description: "facteur partiel de résistance à la portance (vaut 2.3 à l’ELS et 1.4 à l’ELU)",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    gammaRdvElu: {
      id: "gammaRdhElu",
      name: "gammaRdhElu",
      label: "𝛾Rdh,ELU",
      value: 1.4,
      description: "facteur partiel de résistance à la portance (vaut 2.3 à l’ELS et 1.4 à l’ELU)",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    gammaRv: {
      id: "gammaRv",
      name: "gammaRv",
      label: "𝛾Rv",
      value: 1,
      description: "coefficient de modèle associé à la méthode de calcul utilisée",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    },
    Med: {
      id: "Med",
      name: "Med",
      label: "Med (T.m)",
      value: 0,
      description: "Moment déstabilisant",
      unit: { value: 'Tm', label: 'T.m' },
      standard: "",
      reference: ""
    },
    Mrd: {
      id: "Mrd",
      name: "Mrd",
      label: "Mrd (T.m)",
      value: 0,
      description: "Moment stabilisant",
      unit: { value: 'Tm', label: 'T.m' },
      standard: "",
      reference: ""
    },
    sigmaCrit: {
      id: "sigmaCrit",
      name: "sigmaCrit",
      label: "sigma,crit (kPa)",
      value: 0,
      description: "Contrainte critique appliquée au sol",
      unit: { value: "kPa", label: "kPa" },
      standard: "",
      reference: ""
    },
    sigmaLimCrit: {
      id: "sigmaLimCrit",
      name: "sigmaLimCrit",
      label: "sigma,lim (kPa)",
      value: 0,
      description: "Contrainte limite applicable au sol",
      unit: { value: "kPa", label: "kPa" },
      standard: "",
      reference: ""
    },
    surfaceCrit: {
      id: "surfaceCrit",
      name: "surfaceCrit",
      label: "Sc (%)",
      value: 0,
      description: "Taux de compression critique appliquée au sol",
      unit: { value: "%", label: "%" },
      standard: "",
      reference: ""
    },
    surfaceLimCrit: {
      id: "surfaceLimCrit",
      name: "surfaceLimCrit",
      label: "Smini (%)",
      value: 0,
      description: "Taux de compression critique appliquée au sol",
      unit: { value: "%", label: "%" },
      standard: "",
      reference: ""
    }
  },
  validation: {
    projet: {
      required: {
        value: true,
        message: "Le nom du projet ne peut pas être vide"
      }
    },
    dav: {
      custom: {
        isValid: (value) => value >= 0,
        message: "dav ne peut pas être négatif"
      }
    }
  },
  ui: {
    projet: ["projet", "version"],
    // structure: {
    //   materials: ["gammac", "fck", "alphacc", "gammacc", "fyk", "gammas", "c"],
    //   geometry: ["Hp", "ep", "es", "dav", "fav", "dam", "fam", "xbec", "Hb", "Lb"],
    // },
    structure: {
      materials: ["gammac", "fck", "alphacc", "gammacc", "fyk", "gammas", "c"],
      geometry: ["Hp", "ep", "es", "dav", "fav", "dam", "fam", ],
    },
    // soil: {
    //   materials: ["sigmaElsSol", "gammar", "gammae"],
    //   geometry: ["Ht", "Aam", "Bam", "alpha", "phi", "deltaAk", "beta", "deltaOnPhi", "delta", "Ka", "Kp", "Dt", "He1", "He2"]
    // },
    soil: {
      materials: ["sigmaElsSol", "gammar", "gammae","kp"],
      geometry: ["Ht", "Aam", "Bam", "alpha", "phi", "deltaAk", "beta", "deltaOnPhi", "delta", "Ka", "Kp", "Dt"]
    },
    loads: {
      loads: [],
      loadCases: [],
      loadCombination: []
    },
    parameters: {
      coefficients: ["gammaRdh", "gammaRh", "gammaRp", "gammaRdvEls", "gammaRdvElu", "gammaRv"]
    }
  }
};

export default initialState;