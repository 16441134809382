import Wall from './Wall';

class Soil {
  constructor(data) {
    this.data = data;
    this.wall = new Wall(this.data);
  }



}

export default Soil;