import General from '../General';

class Wall extends General {
  constructor(data) {
    super(data);
    this.data = data;
    this.alphacc = 0.85;
    this.gammacc = 1.2;
    this.gammas = 1.15;

  }

  // Angle d'inclinaison de l'écran
  beta({ Hp, fam }) {
    const pi = Math.PI;
    return Hp > 0 ? this.round(Math.atan(fam / Hp) * 180 / pi, 3) : 0;
  }

  // Rapport d'obliquité des contraintes sur l'angle de frottement
  delta({ phi, deltaOnPhi }) {
    return deltaOnPhi * phi;
  }

  // Coefficient de poussée: formule de Coulomb
  Ka({ phi, alpha, beta, delta }) {
    const pi = Math.PI;
    const phiRad = phi * pi / 180;          // Angle de talus naturel
    const alphaRad = alpha * pi / 180;      // Angle effectif du talus
    const betaRad = beta * pi / 180;        // Angle de l'écran
    const deltaRad = delta * pi / 180;      // Angle de frottement sol/structure

    // return Math.tan(pi / 4 - phiRad / 2) ** 2;

    const A = Math.cos(phiRad - betaRad) ** 2;
    const B = Math.cos(betaRad + deltaRad);
    const C = (Math.sin(phiRad + deltaRad) * Math.sin(phiRad - alphaRad)) ** (0.5);
    const D = (Math.cos(betaRad + deltaRad) * Math.cos(betaRad - alphaRad)) ** (0.5);

    return A / (B * (1 + C / D) ** (2));
  }

  // Coefficient de butée: formule de Coulomb
  Kp({ phi, alpha, beta, delta }) {
    const pi = Math.PI;
    const phiRad = phi * pi / 180;
    const alphaRad = alpha * pi / 180;
    const betaRad = beta * pi / 180;
    const deltaRad = delta * pi / 180;


    // return Math.tan(pi / 4 + phiRad / 2) ** 2;


    const A = Math.cos(phiRad + betaRad) ** 2;
    const B = Math.cos(betaRad + deltaRad);
    const C = (Math.sin(phiRad - deltaRad) * Math.sin(phiRad + alphaRad)) ** (0.5);
    const D = (Math.cos(betaRad + deltaRad) * Math.cos(betaRad - alphaRad)) ** (0.5);

    return A / (B * (1 - C / D) ** (2));

  }


  // Poussée sol
  pousseeSol({ roPrime, roSat, zw, phi, alpha, beta, delta, Aam, Bam, z,fam,dam }) {
    // return (z >= zw ? roPrime : roSat) * z * Ka;
    const pi = Math.PI;
    const alphaRad = alpha * pi / 180;
    const phiRad = phi * pi / 180;
    //const ztalus = Aam * Math.tan(alphaRad);
    const ztalus = (Aam - (fam + dam) > 0 ? Aam - (fam + dam) : Aam )* Math.tan(alphaRad);
    if (z <= ztalus) {

      const Ka = this.Ka({ phi, alpha: 0, beta, delta });
      return (z >= zw ? roPrime : roSat) * Ka * z;
    } else {
      const deltaPz = (Aam && alphaRad > 0 ? z - ztalus : 0) ;
      const Ka = this.Ka({ phi, alpha, beta, delta })
      //const deltaPz = (z - Aam * Math.tan(alphaRad)) * Bam * Math.tan(alphaRad) / ((Aam + Bam) * Math.tan(phiRad) - Aam * Math.tan(alphaRad));
      return (z >= zw ? roPrime : roSat) * Ka * (z + deltaPz);
    }
  }

  // Butée sol
  buteeSol({ Kp, roPrime, roSat, zw, phi, z }) {
    return (z >= zw ? roPrime : roSat) * z * Kp;
  }


  // Poussée due aux charges surfaciques: Methode de Krey 
  pousseeSurchargeSurfacique({ Ka, Q, x, L, phi, z,fam,dam,alpha }) {
    const pi = Math.PI;
    const phiRad = phi * pi / 180;
    const alphaRad = alpha * pi / 180;
    // const zinf = x * Math.tan(phiRad);
    // const zsup = (x + L) * Math.tan(pi / 4 + phiRad / 2);
    const zinf = (x-(fam+dam) > 0 ? x-(fam+dam) : x ) * Math.tan(phiRad) - (x-(fam+dam) > 0 ? x-(fam+dam) : x ) * Math.tan(alphaRad);
    const zsup = (x + L -(fam+dam) > 0 ? x + L -(fam+dam) : x + L ) * Math.tan(pi / 4 + phiRad / 2) - (x + L -(fam+dam) > 0 ? x + L -(fam+dam) : x + L ) * Math.tan(alphaRad);

    if (zinf <= z && z <= zsup) {
      return Ka * Q;
    }
    return 0;
  }

  // Poussée due aux charges linéiques: Methode de Krey
  pousseeSurchargeLineique({ Ka, Q, x, phi, z,fam,dam }) {
    const pi = Math.PI;
    const phiRad = phi * pi / 180;
    // const zinf = x * Math.tan(phiRad);
    // const zsup = x * Math.tan(pi / 4 + phiRad / 2);
    const zinf = (x - (fam+dam) > 0 ? x - (fam+dam) : x) * Math.tan(phiRad);
    const zsup = (x - (fam+dam) > 0 ? x - (fam+dam) : x )* Math.tan(pi / 4 + phiRad / 2);
    
    if (zinf <= z && z <= zsup && x > 0) {
      const P = 2 * Q * (1 - Math.sin(phiRad)) / x;
      const H = zsup - zinf > 0 ? zsup - zinf : 0.0000001;
      return Ka * P * (1 - (z - zinf) / H);
    }
    return 0;
  }

  // Poussée due aux charges ponctuelles: Methode de Krey
  pousseeSurchargePonctuelle({ Ka, Q, x, phi, z ,fam,dam}) {
    const pi = Math.PI;
    const phiRad = phi * pi / 180;
    // const zinf = x * Math.tan(phiRad);
    // const zsup = x * Math.tan(pi / 4 + phiRad / 2);

    const zinf = (x - (fam+dam) > 0 ? x - (fam+dam) : x) * Math.tan(phiRad);
    const zsup = (x - (fam+dam) > 0 ? x - (fam+dam) : x )* Math.tan(pi / 4 + phiRad / 2);

    if (zinf <= z && z <= zsup && x > 0) {
      const P = 4 * Q * (1 - Math.sin(phiRad)) / (x -(fam + dam) > 0 ? (x -(fam + dam)) ** 2 : x ** 2);
      const H = zsup - zinf > 0 ? zsup - zinf : 0.0000001;
      return Ka * P * (1 - (z - zinf) / H);
    }
    return 0;
  }

  pousseeSurcharge({ Ka, xi, xj, Qi, Qj, type, phi, z,fam,dam,alpha }) {
    switch (type) {
      case "ponctuelle":
        return this.pousseeSurchargePonctuelle({ Ka, Q: Qi, x: xi, phi, z,fam,dam,alpha });
      case "lineaire":
        return this.pousseeSurchargeLineique({ Ka, Q: Qi, x: xi, phi, z,fam,dam , alpha});
      case "surfacique":
        return this.pousseeSurchargeSurfacique({ Ka, Q: (Qi + Qj) / 2, x: xi, L: (xj - xi), phi, z,fam,dam , alpha});
      default:
        return 0;
    }
  }


  pousseeHydrostatique({ zw, gammae, z }) {
    if (z >= zw) {
      return gammae * (z - zw);
    };
    return 0;
  }


  sousPressionHydrostatique({ He1, He2, gammae, type, x }) {
    const Ls = this.Ls();
    if (type === "imperméable") {
      return 0;
    };

    return gammae * ((He1 - He2) * x / Ls + He2);
  }

  contraintesSurSol({ e, B, N, x }) {
    if (e < B / 6) {
      const sigmaMin = N / B * (1 - 6 * e / B);
      const sigmaMax = N / B * (1 + 6 * e / B);
      return sigmaMax - (sigmaMax - sigmaMin) * x / B;
    } else {
      const sigmaMax = 2 * N / (3 * (B / 2 - e));
      const L = (B / 2 - e) * 3;
      return sigmaMax - sigmaMax * x / L;
    }
  }

}

export default Wall;