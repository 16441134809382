import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	Input
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

const TableElem = ({
	columns,
	data,
	ui,
	onChange
}) => {
	// const [columns, setColumns] = useState(["nom", "valeur", "unitées", "description"]);
	const [rows, setRows] = useState([]);
	useEffect(() => {
		setRows(ui)
	}, []);


	return (
		<div>
			<Table>
				<thead>
					<tr key={0}>
						{columns.map((column, index) => (
							<td key={index} style={{ textAlign: 'center' }}>{column}</td>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<tr key={index * 4} style={{ borderTop: "solid 1px #E8E8E8", borderBottom: "solid 1px #E8E8E8" }}>
							{/* {Object.keys(row).map((item, i) => (
								<td key={i}>{`${row[item]}`}</td>
							))} */}
							<td key={index * 4 + 1}>{`${data[`${row}`]['label']}`}</td>
							<td key={index * 4 + 2}>
								{typeof data[`${row}`]['value'] === "number" ?
									<NumberFormat
										style={{ border: 0, color: `${data[`${row}`]['color'] ? data[`${row}`]['color'] : 'blue'}`, textAlign: 'center' }}
										type="text"
										onChange={onChange(data[`${row}`]['id'])}
										value={data[`${row}`]['value']}
									/>
									:
									<Input
										style={{ border: 0, color: "blue", textAlign: 'center' }}
										type="text"
										onChange={onChange(data[`${row}`]['id'])}
										value={data[`${row}`]['value']}
										disableUnderline={true}
									/>
								}
							</td>
							<td key={index * 4 + 3}>{`${data[`${row}`]['unit'].label}`}</td>
							<td key={index * 4 + 4}>{`${data[`${row}`]['description']}`}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
}

export default TableElem;