import Eurocode2 from './Eurocode2';

class Concrete {
  constructor(data, standard) {
    this.data = data;
    this.standard = standard;
    this.concreteStandard = {
      Eurocode2: new Eurocode2(this.data, this)
    };
    this.currentStandard = this.concreteStandard[this.standard]
  }

  concreteAnalysis() {

  }
}

export default Concrete;