import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  Button
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import TableElem from '../../../../Components/TableElem';
import DataTable from '../../Components/DataTable/DataTable';

const Bilan = ({
  state,
  handleChangeState
}) => {
  console.log(state);
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <CardElem
          title="Stabilité externe"
          subtitle="Bilan de la stabilité externe"
        >
          <TableContainer component={Paper}>
            <Table className="table" size="small" aria-label="simple table">
              <TableBody>
                {state.data.bilanStabiliteExterne.value.map((bilan, index) => (
                  <TableRow key={`${index}`}>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        style={{ border: 0 }}
                        value={bilan.name}
                        disableUnderline={true}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Button
                        variant="contained"
                        style={{ width: '100%', color: 'white', backgroundColor: `${bilan.value < 1 ? 'green' : 'red'}`, textTransform: 'none' }}
                      >
                        {/* {`${Math.round(bilan.value * 100 * 100) / 100} %`} */}
                        {`${bilan.label}`}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardElem>
      </Grid>
      <Grid item md={6}>
        <CardElem
          title="Stabilité interne"
          subtitle="Bilan de la stabilité interne"
        >
          <TableContainer component={Paper}>
            <Table className="table" size="small" aria-label="simple table">
              <TableBody>
                {state.data.bilanStabiliteInterne.value.map((bilan, index) => (
                  <TableRow key={`${index}`}>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        style={{ border: 0 }}
                        value={bilan.name}
                        disableUnderline={true}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Button
                        variant="contained"
                        style={{ width: '100%', color: 'white', backgroundColor: 'green', textTransform: 'none' }}
                      >
                        {`${Math.round(bilan.value * 100) / 100} cm²`}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardElem>
      </Grid>
    </Grid>
  )
}

export default Bilan;