import General from './General';
import Soil from './Soil';


class ExternalStability extends General {
  constructor(data) {
    super(data);
    this.data = data;
    this.soil = new Soil(this.data);
  }


  Ls() {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    return dav + fav + ep + fam + dam;
  }

  // Géométrie: Semelle de fondation
  SML() {
    const es = this.data.es.value;
    const gammac = this.data.gammac.value;
    const Ls = this.Ls();

    return {
      id: "SML",
      name: "SML",
      label: 'SML',
      description: "Semelle de fondation",
      gamma: gammac,
      loadCase: "G0",
      value: [
        { x: 0, y: 0 },
        { x: Ls, y: 0 },
        { x: Ls, y: es },
        { x: 0, y: es },
        { x: 0, y: 0 }
      ]
    };
  }

  // Géométrie: Bêche
  BEC() {
    const xbec = this.data.xbec.value;
    const gammac = this.data.gammac.value;
    const Lb = this.data.Lb.value;
    const Hb = this.data.Hb.value;

    return {
      id: "BEC",
      name: "BEC",
      label: 'BEC',
      description: "Bêche",
      gamma: gammac,
      loadCase: "G0",
      value: [
        { x: xbec, y: 0 },
        { x: xbec, y: -Hb },
        { x: xbec + Lb, y: -Hb },
        { x: xbec + Lb, y: 0 },
        { x: xbec, y: 0 }
      ]
    };
  }

  // Géométrie: Voile (part constante)
  M() {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const Hp = this.data.Hp.value;
    const gammac = this.data.gammac.value;

    return {
      id: "M",
      name: "M",
      label: 'M',
      description: "Voile (part constante)",
      gamma: gammac,
      loadCase: "G0",
      value: [
        { x: dav + fav, y: es },
        { x: dav + fav + ep, y: es },
        { x: dav + fav + ep, y: es + Hp },
        { x: dav + fav, y: es + Hp },
        { x: dav + fav, y: es }
      ]
    };
  }

  // Géométrie: Voile (fruit côté aval)
  FG() {
    const dav =this.data.dav.value == 0?  0.00001 : this.data.dav.value;
    const fav = this.data.fav.value;
    const es = this.data.es.value;
    const Hp = this.data.Hp.value;
    const gammac = this.data.gammac.value;

    return {
      id: "FG",
      name: "FG",
      label: 'FG',
      description: "Voile (fruit côté aval)",
      gamma: gammac,
      loadCase: "G0",
      value: [
        { x: dav, y: es },
        { x: dav + fav, y: es },
        { x: dav + fav, y: es + Hp },
        { x: dav, y: es },
      ]
    };
  }

  // Géométrie: Voile (fruit côté aval)
  FD() {
    const dav =this.data.dav.value == 0?  0.00001 : this.data.dav.value;
    const fav = this.data.fav.value;
    const fam = this.data.fam.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const Hp = this.data.Hp.value;
    const gammac = this.data.gammac.value;

    return {
      id: "FD",
      name: "FD",
      label: 'FD',
      description: "Voile (fruit côté amont)",
      gamma: gammac,
      loadCase: "G0",
      value: [
        { x: dav + fav + ep, y: es },
        { x: dav + fav + ep + fam, y: es },
        { x: dav + fav + ep, y: es + Hp },
        { x: dav + fav + ep, y: es },
      ]
    };
  }

  // Géométrie: Remblai aval - part uniforme
  RG() {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const es = this.data.es.value;
    const Hp = this.data.Hp.value;
    const Dt = this.data.Dt.value;
    const gammar = this.data.gammar.value;

    const value = [
      { x: 0, y: es },
      { x: dav, y: es },
      { x: (Hp === 0 ? 0 : dav + (Dt - es) / Hp * fav), y: Dt },
      { x: 0, y: Dt },
      { x: 0, y: es },
    ];

    return {
      id: "RG",
      name: "RG",
      label: 'RG',
      description: "Remblai aval",
      gamma: gammar,
      loadCase: "G0",
      value
    };
  }

  // Géométrie: Remblai amont
  RD() {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const Aam = this.data.Aam.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;
    const gammar = this.data.gammar.value;


    const value = [
      { x: dav + fav + ep + fam + dam, y: es },
      { x: dav + fav + ep + fam + dam, y: Ht + es + Math.max((fam + dam - Aam), 0) * Math.tan(alpha * Math.PI / 180) },
      { x: dav + fav + ep + (Hp - Ht) / Hp * fam + Math.min(Aam, fam + dam), y: Ht + es },
      { x: dav + fav + ep + (Hp - Ht) / Hp * fam, y: Ht + es },
      { x: dav + fav + ep + fam, y: es },
    ]

    return {
      id: "RD",
      name: "RD",
      label: 'RD',
      description: "Remblai amont",
      gamma: gammar,
      loadCase: "G0",
      value
    };
  }

  RDD() {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const Aam = this.data.Aam.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;
    const gammar = this.data.gammar.value;

    let value = [];
    if (Aam >= fam + dam) {
      value = [
        // { x: dav + fav + ep + fam + dam, y: 0 },
        { x: dav + fav + ep + Aam + Bam, y: 0 },
        { x: dav + fav + ep + Aam + Bam, y: Ht + es + Math.max(Bam, 0) * Math.tan(alpha * Math.PI / 180) },
        { x: dav + fav + ep + Aam, y: Ht + es },
        { x: dav + fav + ep + fam + dam, y: Ht + es },
        { x: dav + fav + ep + fam + dam, y: 0 },
      ]
    } else {
      value = [
        // { x: dav + fav + ep + fam + dam, y: 0 },
        { x: dav + fav + ep + Aam + Bam, y: 0 },
        { x: dav + fav + ep + Aam + Bam, y: Ht + es + Math.max(Bam, 0) * Math.tan(alpha * Math.PI / 180) },
        { x: dav + fav + ep + fam + dam, y: Ht + es + Math.max((fam + dam - Aam), 0) * Math.tan(alpha * Math.PI / 180) },
        { x: dav + fav + ep + fam + dam, y: 0 },
      ]
    }
    return {
      id: "RDD",
      name: "RDD",
      label: 'RDD',
      description: "Remblai avant amont",
      gamma: gammar,
      loadCase: "G0",
      value
    };
  }


  // Géométrie: Eau aval
  HEG() {
    const He2 = this.data.He2.value;
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const Hp = this.data.Hp.value;
    const gammae = this.data.gammae.value;

    return {
      id: "HEG",
      name: "HEG",
      label: 'HEG',
      description: "eau aval",
      gamma: gammae,
      loadCase: "G0",
      value: [
        { x: 0, y: He2 },
        { x: dav + fav, y: He2 }
      ]
    };
  }


  // Géométrie: Eau amont
  HED() {
    const He1 = this.data.He1.value;
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const Aam = this.data.Aam.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;
    const gammae = this.data.gammae.value;

    return {
      id: "HED",
      name: "HED",
      label: 'HED',
      description: "Eau amont",
      gamma: gammae,
      loadCase: "G0",
      value: [
        { x: dav + fav + ep, y: He1 },
        { x: dav + fav + ep + Aam + Bam, y: He1 }
      ]
    };
  }

  // Géométrie: Charges sur remblai
  drawQi(chargeSurRemblai) {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const Aam = this.data.Aam.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;
    const gammar = this.data.gammar.value;
    const {
      label,
      xi,
      xj,
      Qi,
      Qj,
      type
    } = chargeSurRemblai;

    if (xj >= xi && type === "surfacique") {
      if (xj <= Aam) {
        return [
          { x: dav + fav + ep + xi, y: Ht + es },
          { x: dav + fav + ep + xj, y: Ht + es },
          { x: dav + fav + ep + xj, y: Ht + es + Qj },
          { x: dav + fav + ep + xi, y: Ht + es + Qi },
          { x: dav + fav + ep + xi, y: Ht + es },
        ]
      }
      if (xi < Aam && Aam < xj) {

        const Qam = (xj - Aam) / (xj - xi) * (Qi - Qj) + Qj;

        return [
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es },
          { x: dav + fav + ep + Aam, y: Ht + es },
          { x: dav + fav + ep + Math.min(xj, Aam + Bam), y: Ht + es + Math.max((Math.min(xj, Aam + Bam) - Aam), 0) * Math.tan(alpha * Math.PI / 180) },
          { x: dav + fav + ep + Math.min(xj, Aam + Bam), y: Ht + es + Math.max((Math.min(xj, Aam + Bam) - Aam), 0) * Math.tan(alpha * Math.PI / 180) + Qj },
          { x: dav + fav + ep + Aam, y: Ht + es + Qam },
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Qi },
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es },
        ]
      }
      if (Aam <= xi) {
        return [
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Math.max(Math.min(xi, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) },
          { x: dav + fav + ep + Math.min(xj, Aam + Bam), y: Ht + es + Math.max(Math.min(xj, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) },
          { x: dav + fav + ep + Math.min(xj, Aam + Bam), y: Ht + es + Math.max(Math.min(xj, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) + Qj },
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Math.max(Math.min(xi, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) + Qi },
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Math.max(Math.min(xi, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) },
        ]
      }
    } else {
      if (xi <= Aam) {
        return [
          { x: dav + fav + ep + xi, y: Ht + es },
          { x: dav + fav + ep + xi, y: Ht + es + Qi },
          { x: dav + fav + ep + xi, y: Ht + es }
        ]
      }
      if (Aam <= xi) {
        return [
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Math.max(Math.min(xi, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) },
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Math.max(Math.min(xi, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) + Qi },
          { x: dav + fav + ep + Math.min(xi, Aam + Bam), y: Ht + es + Math.max(Math.min(xi, Aam + Bam) - Aam, 0) * Math.tan(alpha * Math.PI / 180) }
        ]
      }
    }
  }


  QD() {
    const dav = this.data.dav.value;
    const fav = this.data.fav.value;
    const ep = this.data.ep.value;
    const es = this.data.es.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const Ht = this.data.Ht.value;
    const Hp = this.data.Hp.value;
    const Aam = this.data.Aam.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;
    const gammar = this.data.gammar.value;

    const chargesSurRemblai = this.data.chargesSurRemblai.value;

    let value = [];

    for (let chargeSurRemblai of chargesSurRemblai) {
      const {
        label
      } = chargeSurRemblai;

      value.push({
        label,
        value: this.drawQi(chargeSurRemblai)
      });
    }

    return {
      id: "QD",
      name: "QD",
      label: "QD",
      description: "Charges sur Remblai",
      gamma: "",
      loadCase: "Q",
      value
    };
  }






  // Hauteur du terrain au niveau de l'ecran II
  Hmax() {
    const Ht = this.data.Ht.value;
    const es = this.data.es.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;

    // return Ht + Bam * Math.tan(alpha * Math.PI / 180);
    return Ht;
  }


  // Angle d'inclinaison de l'écran
  beta() {
    const Hp = this.data.Hp.value;
    const fam = this.data.fam.value;
    return this.round(this.soil.wall.beta({ Hp, fam }), 3);
  }

  // Rapport d'obliquité des contraintes sur l'angle de frottement
  delta() {
    const phi = this.data.phi.value;
    const deltaOnPhi = this.data.deltaOnPhi.value;
    return this.round(this.soil.wall.delta({ phi, deltaOnPhi }), 3);
  }

  Ka() {
    const phi = this.data.phi.value;
    const alpha = this.data.alpha.value;
    const beta = this.beta();
    const delta = this.delta();

    return this.round(this.soil.wall.Ka({ phi, alpha, beta, delta }), 3);
  }


  Kp() {
    const phi = this.data.phi.value;
    const alpha = this.data.alpha.value;
    const beta = this.beta();
    const delta = this.delta();

    return this.round(this.soil.wall.Kp({ phi, alpha, beta, delta }), 3);
  }


  // Efforts: Poids propres

  poidsPropres() {
    const items = [
      this.SML(),
      this.BEC(),
      this.M(),
      this.FG(),
      this.FD(),
      this.RG(),
      this.RD()
    ];

    const poidsPropresAnalysis = items.map((item, index) => {
      const Ls = this.Ls();
      const gamma = item.gamma;
      const xg = this.geometryFunctions.getXg(item.value, []);
      const zg = this.geometryFunctions.getYg(item.value, []);
      const S = this.geometryFunctions.getArea(item.value, []) < 0 ? 0 : this.geometryFunctions.getArea(item.value, []);
      const V = S * gamma;
      const H = 0;
      const x1 = xg - Ls / 2;
      const M = -V * x1;
      const BLst = x1;
      const Mst = V * BLst;
      const Mrv = 0;


      return {
        label: item.label,
        description: item.description,
        loadCase: item.loadCase,
        xg: this.round(xg, 3),
        zg: this.round(zg, 3),
        S: this.round(S, 3),
        gamma: this.round(gamma, 3),
        V: this.round(V, 3),
        H: this.round(H, 3),
        x1: this.round(x1, 3),
        M: this.round(M, 3),
        BLst: this.round(BLst, 3),
        Mst: this.round(Mst, 3),
        Mrv: this.round(Mrv, 3)
      }
    })


    const Ls = this.Ls();
    const He1 = (this.data.He1.value > 0) ? this.data.He1.value : 0;
    const He2 = (this.data.He2.value > 0) ? this.data.He2.value : 0;
    const gammae = this.data.gammae.value;
    const xg = Ls / 2;
    const zg = 0;
    const V = - gammae * Ls * (He1 + He2) / 2;
    const H = 0;
    const x1 = Ls / 2;
    const Mb = V * x1;
    const BLst = x1;
    const Mst = 0;
    const Mrv = -V * BLst;

    let M = (Ls / 6) * gammae * (He2 - He1) / 2;

    const sousPressionHydrostatique = {
      label: "PSE",
      description: "Eau - sous-pression",
      loadCase: "G0",
      xg: this.round(xg, 3),
      zg: this.round(zg, 3),
      S: 0,
      gamma: this.round(gammae, 3),
      V: this.round(V, 3),
      H: this.round(H, 3),
      x1: this.round(x1, 3),
      M: this.round(M, 3),
      BLst: this.round(BLst, 3),
      Mst: this.round(Mst, 3),
      Mrv: this.round(Mrv, 3)
    }


    return [
      ...poidsPropresAnalysis,
      sousPressionHydrostatique
    ];
  }


  // Efforts: Poussées du terrain

  pousseesTerrain() {
    const pi = Math.PI;
    const Ht = this.data.Ht.value;
    const es = this.data.es.value;
    const ep = this.data.ep.value;
    const Bam = this.data.Bam.value;
    const alpha = this.data.alpha.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const Aam = this.data.Aam.value;
    const Hmax = Ht + es + (fam + dam - Aam > 0 ? fam +dam -Aam: 0) * Math.tan(alpha * pi / 180);
    //console.log('Hmax',Hmax)
    //const Hmax = Ht + es;
    const Ztop = Hmax;
    const Zbot = 0;
    const Zw = this.data.He1.value;
    //const pi = Math.PI;
    const gammar = this.data.gammar.value;
    const gammae = this.data.gammae.value;
    const phi = this.data.phi.value;
    //const Aam = this.data.Aam.value;
    //const Bam = this.data.Bam.value;
    //const alpha = this.data.alpha.value;
    const Hp = this.data.phi.value;
    //const fam = this.data.fam.value;
    const beta = this.beta();
    const delta = this.delta();

    const zw = Hmax - Zw;

    let soilPressure = [];

    for (let i = 0; i <= 100; i++) {
      const deltaZ = (Ztop - Zbot) / (100);

      const Z = Hmax - i * deltaZ;
      const z = i * deltaZ;

      const roSat = gammar;
      const roPrime = gammar - gammae;

      const sigmaV = this.soil.wall.pousseeSol({ roPrime, roSat, zw, phi, alpha, beta, delta, Aam, Bam, z,fam,dam })
      const dH = sigmaV * deltaZ * Math.cos((delta + beta) * pi / 180) / Math.cos(beta * pi / 180) ** 2;
      const dV = dH * Math.tan(beta * pi / 180);
      const BLrv = Hmax - z;
      const dMrv = dH * BLrv;
      const dMst = dV * this.Ls();

      const dM = dMrv - dMst;
      soilPressure.push({
        Z,
        z,
        deltaZ,
        roSat,
        roPrime,
        sigmaV,
        dH,
        dV,
        dM,
        BLrv,
        dMrv,
        dMst
      })
    }

    return soilPressure;
  }

  PDR() {
    const soilPressure = this.pousseesTerrain();
    const V = soilPressure.reduce((nsum, ni) => nsum + ni.dV, 0);
    const H = soilPressure.reduce((nsum, ni) => nsum + ni.dH, 0);
    const M = soilPressure.reduce((nsum, ni) => nsum + ni.dM, 0);
    const Mst = soilPressure.reduce((nsum, ni) => nsum + ni.dMst, 0);
    const Mrv = soilPressure.reduce((nsum, ni) => nsum + ni.dMrv, 0);


    return {
      id: "PDR",
      name: "PDR",
      label: 'PDR',
      description: "Poussée remblai amont",
      loadCase: "G1",
      V: this.round(V, 3),
      H: this.round(H, 3),
      M: this.round(M, 3),
      Mst: this.round(Mst, 3),
      Mrv: this.round(Mrv, 3)
    }
  }


  // Efforts: Poussées du aux charges

  pousseesChargesRemblai({ xi, xj, Qi, Qj, type }) {
    // const Hmax = this.Hmax();
    const Ht = this.data.Ht.value;
    const es = this.data.es.value;

    const Hmax = Ht + es;
    const Ztop = Hmax;
    const Zbot = 0;
    const Ze = 0;
    const pi = Math.PI;
    const beta = this.data.beta.value;
    const phi = this.data.phi.value;
    const fam = this.data.fam.value;
    const dam = this.data.dam.value;
    const alpha = this.data.alpha.value;

    let loadsPressure = [];
    for (let i = 0; i <= 100; i++) {
      const deltaZ = (Ztop - Zbot) / (100);
      const Z = Hmax - i * deltaZ;
      const z = i * deltaZ;
      const Ka = this.Ka();
      const sigmaV = this.soil.wall.pousseeSurcharge({ Ka, xi, xj, Qi, Qj, type, phi, z,fam,dam,alpha});
      const dH = sigmaV * deltaZ * Math.cos(beta * pi / 180);
      const dV = dH * Math.tan(beta * pi / 180);
      const BLrv = Hmax - z;
      const dMrv = dH * BLrv;
      const dMst = dV * this.Ls();

      const dM = dMrv - dMst;
      loadsPressure.push({
        Z,
        z,
        sigmaV,
        dH,
        dV,
        dM,
        BLrv,
        dMrv,
        dMst
      })
    }
    return loadsPressure;
  }

  PQR() {
    const chargesSurRemblai = this.data.chargesSurRemblai.value;


    let pousseesChargesRemblai = [];
    for (let chargeSurRemblai of chargesSurRemblai) {

      const {
        xi,
        xj,
        Qi,
        Qj,
        type
      } = chargeSurRemblai;
      const loadsPressure = this.pousseesChargesRemblai({ xi, xj, Qi, Qj, type });
      const V = loadsPressure.reduce((nsum, ni) => nsum + ni.dV, 0);
      const H = loadsPressure.reduce((nsum, ni) => nsum + ni.dH, 0);
      const M = loadsPressure.reduce((nsum, ni) => nsum + ni.dM, 0);
      const Mst = loadsPressure.reduce((nsum, ni) => nsum + ni.dMst, 0);
      const Mrv = loadsPressure.reduce((nsum, ni) => nsum + ni.dMrv, 0);
      pousseesChargesRemblai.push({
        ...chargeSurRemblai,
        V: this.round(V, 3),
        H: this.round(H, 3),
        M: this.round(M, 3),
        Mst: this.round(Mst, 3),
        Mrv: this.round(Mrv, 3)
      })
    }

    return pousseesChargesRemblai;
  }

  // Efforts: Poussées hydrostatique

  pousseesHydrostatique({ Zw, type, gammae }) {
    // const Hmax = this.Hmax();
    const Ht = this.data.Ht.value;
    const es = this.data.es.value;

    const Hmax = Ht + es;
    const Ztop = Hmax;
    const Zbot = 0;
    const zw = Hmax - Zw;
    const pi = Math.PI;
    const beta = this.beta();

    let waterPressure = [];
    for (let i = 0; i <= 100; i++) {
      const deltaZ = (Ztop - Zbot) / (100);
      const Z = Hmax - i * deltaZ;
      const z = i * deltaZ;
      const sigmaV = ((type === "amont") ? 1 : -1) * this.soil.wall.pousseeHydrostatique({ zw, gammae, z });
      const dH = sigmaV * deltaZ * Math.cos(beta * pi / 180);
      const dV = dH * Math.tan(beta * pi / 180);
      const BLrv = Hmax - z;
      const dMrv = dH * BLrv;
      const dMst = dV * this.Ls();

      const dM = dMrv - dMst;
      waterPressure.push({
        Z,
        z,
        sigmaV,
        dH,
        dV,
        dM,
        BLrv,
        dMrv,
        dMst
      })
    }
    return waterPressure;
  }

  PDE() {
    const He1 = this.data.He1.value;
    const gammae = this.data.gammae.value;
    const waterPressure = this.pousseesHydrostatique({ Zw: He1, type: "amont", gammae });
    const V = waterPressure.reduce((nsum, ni) => nsum + ni.dV, 0);
    const H = waterPressure.reduce((nsum, ni) => nsum + ni.dH, 0);
    const M = waterPressure.reduce((nsum, ni) => nsum + ni.dM, 0);
    const Mst = waterPressure.reduce((nsum, ni) => nsum + ni.dMst, 0);
    const Mrv = waterPressure.reduce((nsum, ni) => nsum + ni.dMrv, 0);

    return {
      id: "PDE",
      name: "PDE",
      label: "PDE",
      description: "Poussée hydrostatique amont",
      loadCase: "E",
      V: this.round(V, 3),
      H: this.round(H, 3),
      M: this.round(M, 3),
      Mst: this.round(Mst, 3),
      Mrv: this.round(Mrv, 3)
    }
  }

  PGE() {
    const He2 = this.data.He2.value;
    const gammae = this.data.gammae.value;
    const waterPressure = this.pousseesHydrostatique({ Zw: He2, type: "aval", gammae });
    const V = waterPressure.reduce((nsum, ni) => nsum + ni.dV, 0);
    const H = waterPressure.reduce((nsum, ni) => nsum + ni.dH, 0);
    const M = waterPressure.reduce((nsum, ni) => nsum + ni.dM, 0);
    const Mst = waterPressure.reduce((nsum, ni) => nsum + ni.dMst, 0);
    const Mrv = waterPressure.reduce((nsum, ni) => nsum + ni.dMrv, 0);


    return {
      id: "PGE",
      name: "PGE",
      label: "PGE",
      description: "Poussée hydrostatique aval",
      loadCase: "E",
      V: this.round(V, 3),
      H: this.round(H, 3),
      M: this.round(M, 3),
      Mst: this.round(Mst, 3),
      Mrv: this.round(Mrv, 3)
    }
  }

  poussees() {
    const items = [
      this.PDR(),
      ...this.PQR(),
      this.PDE(),
      this.PGE()
    ];

    return items;
  }

  // Hauteur du terrain de butée
  buteeTerrain() {
    const Dt = this.data.Dt.value;
    const Hb = this.data.Hb.value;
    const es = this.data.es.value;

    const Ztop = Dt;
    const Zbot = -Hb;
    const Zw = this.data.He2.value;
    const pi = Math.PI;
    const gammar = this.data.gammar.value;
    const gammae = this.data.gammae.value;
    const phi = this.data.phi.value;
    const Hp = this.data.phi.value;
    const fam = this.data.fam.value;
    const beta = this.soil.wall.beta({ Hp, fam });

    const zw = Ztop - Zw;

    let soilPressure = [];

    for (let i = 0; i <= 100; i++) {
      const deltaZ = (Ztop - Zbot) / (100);

      const Z = Ztop - i * deltaZ;
      const z = i * deltaZ;

      const roSat = gammar;
      const roPrime = gammar - gammae;

      let sigmaV = 0;
      let dH = 0;
      let dV = 0;
      let dM = 0;
      let BLrv = 0;
      let dMrv = 0;
      let dMst = 0;


      if (0 <= Z && Z <= es) {
        const Kp = this.Kp();
        sigmaV = this.soil.wall.buteeSol({ Kp, roPrime, roSat, zw, phi, z })
        dH = -sigmaV * deltaZ * Math.cos(beta * pi / 180);
        dV = dH * Math.tan(beta * pi / 180);
        BLrv = Ztop - z;
        dM = dH * BLrv;
        dMrv = 0;
        dMst = -dM;
      }

      if (-Hb <= Z && Z <= 0) {
        const Kp = this.Kp();
        sigmaV = this.soil.wall.buteeSol({ Kp, roPrime, roSat, zw, phi, z })
        dH = -sigmaV * deltaZ * Math.cos(beta * pi / 180);
        dV = dH * Math.tan(beta * pi / 180);
        BLrv = Dt - z;
        dM = dH * BLrv;
        dMrv = dM;
        dMst = 0;
      }
      soilPressure.push({
        Z,
        z,
        roSat,
        roPrime,
        sigmaV,
        dH,
        dV,
        dM,
        BLrv,
        dMrv,
        dMst
      })
    }

    return soilPressure;
  }

  BGR() {
    const soilPressure = this.buteeTerrain();
    const V = soilPressure.reduce((nsum, ni) => nsum + ni.dV, 0);
    const H = soilPressure.reduce((nsum, ni) => nsum + ni.dH, 0);
    const M = soilPressure.reduce((nsum, ni) => nsum + ni.dM, 0);
    const Mst = soilPressure.reduce((nsum, ni) => nsum + ni.dMst, 0);
    const Mrv = soilPressure.reduce((nsum, ni) => nsum + ni.dMrv, 0);

    return {
      id: "BGR",
      name: "BGR",
      label: "BGR",
      description: "Butée terrain aval",
      loadCase: "G0",
      V: this.round(V, 3),
      H: this.round(H, 3),
      M: this.round(M, 3),
      Mst: this.round(Mst, 3),
      Mrv: this.round(Mrv, 3)
    }
  }

  butees() {
    const items = [
      this.BGR()
    ];

    return items;
  }

  casDeCharges() {
    const casDeCharges = this.data.casDeCharges;
    return casDeCharges;
  }

  // Bilan des efforts externes
  bilanEffortsExternes() {
    const forces = [
      ...this.poidsPropres(),
      ...this.poussees(),
      ...this.butees()
    ];


    const casDeCharges = this.casDeCharges();
    const bilan = casDeCharges.value.map((casDeCharge) => {
      const V = forces.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.V : nsum, 0);
      const H = forces.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.H : nsum, 0);
      const M = forces.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.M : nsum, 0);
      const Mrv = forces.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.Mrv : nsum, 0);
      const Mst = forces.reduce((nsum, ni) => ni.loadCase === casDeCharge.value ? nsum + ni.Mst : nsum, 0);

      return {
        ...casDeCharge,
        V: this.round(V, 3),
        H: this.round(H, 3),
        M: this.round(M, 3),
        Mst: this.round(Mst, 3),
        Mrv: this.round(Mrv, 3)
      }
    })

    return bilan;
  }


  combinaisonsDeCharges() {
    const combinaisonsDeCharges = this.data.combinaisonsDeCharges;
    return combinaisonsDeCharges;
  }

  sigmaRef(V, e) {
    const B = this.Ls();

    if (B > 0) {
      if (e < B / 6) {
        return V / B * (1 + 3 * e / B) * 10;
      } else {
        return V / (B - 2 * e) * 10;
      }
    } else {
      return 0;
    }
  }

  kp() {
    const De = this.data.Dt.value;
    const B = this.Ls();

    // Paramètre de la catégorie de sol Q3 - Sables et Graves
    const kp0 = 1
    const a = 0.3;
    const b = 0.05;
    const c = 2;
    const kpmax = 1.393;

    console.log("kpr", Math.min(kpmax, kp0 + (a + b * De / B) * (1 - Math.exp(-c * De / B))))
    return this.round(Math.min(kpmax, kp0 + (a + b * De / B) * (1 - Math.exp(-c * De / B))),3);
  }

  idelta(H, V) {
    const B = this.Ls();
    const typeDeSol = this.data.typeDeSol.value;
    const pi = Math.PI;
    const delta = Math.atan(Math.abs(H) / V);
    const De = this.data.Dt.value;

    // if (typeDeSol === "solFrottant") {
    if (delta < pi / 4) {
      console.log("idelta", (1 - 2 * delta / pi) ** (2) - 2 * delta / pi * (2 - 3 * 2 * delta / pi) * Math.exp(-De / B))
      return (1 - 2 * delta / pi) ** (2) - 2 * delta / pi * (2 - 3 * 2 * delta / pi) * Math.exp(-De / B);
    }
    return (1 - 2 * delta / pi) ** (2) - (1 - 2 * delta / pi) ** (2) * Math.exp(-De / B);
    // } else {
    //   return Math.min(1, (1 - delta / 90) ** (2));
    // }
  }
  
  qnet(idelta)
  {
    const kp = this.kp();
    const ple = this.data.sigmaElsSol.value;
    const qnet = kp * ple * idelta;

    return qnet;

  }
  
  VdLim({ idelta, e, type, gammaRdvEls, gammaRdvElu, gammaRv }) {
    const g = 9.81;
    const B = this.Ls();
    const kp = this.kp();
    const De = this.data.Dt.value;
    const ple = this.data.sigmaElsSol.value;
    const gammaRemblai = this.data.gammar.value;
    const qnet = this.qnet(idelta);
    // const qnet = kp * ple * idelta;
    const ie = 1 - 2 * e / B;

    switch (type) {
      case 'ELS':
        return B * De * gammaRemblai + B * ie * qnet / (gammaRdvEls * gammaRv) / g;
      case 'ELU':
        return B * De * gammaRemblai + B * ie * qnet / (gammaRdvElu * gammaRv) / g;
      default:
        return 0;
    }
  }

  surfaceLim({ type }) {
    switch (type) {
      case 'ELS':
        return 0.5;
      case 'ELU':
        return 0.1;
      default:
        return 0;
    }
  }

  // Valeur de calcul de la résistance au glissement de la semelle du mur sur le terrain
  Rhd({ gammaRdh, gammaRh, V, deltaAk }) {
    const pi = Math.PI;
    return this.round(V * Math.tan(deltaAk * pi / 180) / (gammaRdh * gammaRh), 3);
  }

  // Coefficient de sécurité pour la vérification du Glissement
  Sgl({ Rhd, H }) {
    return (Rhd === 0) ? 0 : this.round(Math.abs((H / Rhd)), 3)
  }

  // Coefficient de sécurité pour la vérification de l'Excentrement
  Sext({ e, B }) {
    return 1 - 2 * e / B;
  }

  SextLim(type) {
    switch (type) {
      case 'ELS':
        return 1 / 2;
      case 'ELU':
        return 1 / 15;
      default:
        return 0;
    }
  }

  calculStabiliteExterne() {
    const pi = Math.PI;
    const phi = this.data.phi.value;
    const deltaAk = this.data.deltaAk.value;
    const sigmaElsSol = this.data.sigmaElsSol.value;
    const gammaRdh = this.data.gammaRdh.value;
    const gammaRh = this.data.gammaRh.value;
    const gammaRdvEls = this.data.gammaRdvEls.value;
    const gammaRdvElu = this.data.gammaRdvElu.value;
    const gammaRv = this.data.gammaRv.value;
    const B = this.Ls();

    const combinaisonsDeCharges = this.combinaisonsDeCharges();
    const bilanEffortsExternes = this.bilanEffortsExternes();

    const calculStabilite = combinaisonsDeCharges.value.map(combinaison => {
      const { loadCases } = combinaison;
      let V = 0;
      let H = 0;
      let M = 0;
      let Mst = 0;
      let Mrv = 0;
      for (let loadCase of loadCases) {
        for (let bilanEffort of bilanEffortsExternes) {
          if (bilanEffort.value === loadCase.id) {
            V += bilanEffort.V * loadCase.value;
            H += bilanEffort.H * loadCase.value;
            M += bilanEffort.M * loadCase.value;
            Mst += bilanEffort.Mst * loadCase.value;
            Mrv += bilanEffort.Mrv * loadCase.value;

          }
        }
      }

      let Me = this.round(M > 0 ? M : 0, 3);
      let e = this.round(Me / V, 3);
      let Rhd = this.Rhd({ gammaRdh, gammaRh, V, deltaAk });
      let Sgl = this.Sgl({ Rhd, H });
      let Srv = Mst === 0 ? 0 : this.round(Mst / Mrv, 3);
      let Sext = this.round(this.Sext({ e, B }), 3);
      let SextLim = this.round(this.SextLim(combinaison.type), 3);
      let coefExt = Sext === 0 ? Sext : SextLim / Sext;
      let idelta = this.round(this.idelta(H, V), 3);
      let qnet = this.round(this.qnet(idelta),3);
      let sigmaSol = this.round(this.sigmaRef(V, e), 3);
      let VdLim = this.round(this.VdLim({ idelta, e, type: combinaison.type, gammaRdvEls, gammaRdvElu, gammaRv }), 3);
      let Sbearing = this.round(V / VdLim, 3);
      let Ssigma = this.round(V / VdLim, 3);
      let surfaceComprimee = this.ScSurfaceComprimee();
      let surfaceLim = this.surfaceLim({ type: combinaison.type });
      let Ssurface = surfaceLim / surfaceComprimee;
      return {
        ...combinaison,
        V: this.round(V, 3),
        H: this.round(H, 3),
        M: Me,
        Mst: this.round(Mst, 3),
        Mrv: this.round(Mrv, 3),
        Rhd,
        Sgl,
        Srv,
        Sext,
        SextLim,
        coefExt: this.round(coefExt, 3),
        e,
        idelta,
        qnet,
        sigmaSol,
        VdLim,
        Sbearing,
        Ssigma,
        surfaceComprimee,
        surfaceLim,
        Ssurface
      }
    })

    return calculStabilite;
  }

  HdMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => (a.Sgl > b.Sgl)? a : b);

    return {
      id: "Hd",
      name: "Hd",
      label: "Hd (T)",
      value: this.round(highest.H, 3),
      description: "Valeur de calcul de la composante horizontale",
      unit: { value: 'T', label: 'T' },
      standard: "",
      reference: ""
    }
  }


  RhdMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => (a.Sgl > b.Sgl)? a : b);

    return {
      id: "Rhd",
      name: "Rhd",
      label: "Rhd (T)",
      value: this.round(highest.Rhd, 3),
      description: "Valeur de calcul de la résistance au glissement",
      unit: { value: 'T', label: 'T' },
      standard: "",
      reference: ""
    }
  }

  SglMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => (a.Sgl > b.Sgl)? a : b);
    return {
      id: "SglMax",
      name: "SglMax",
      label: "Sgl,max",
      value: this.round(highest.Sgl, 3),
      description: "Coefficient de sécurité au glissement max obtenu",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  combiSglMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => (a.Sgl > b.Sgl)? a : b);
    return {
      id: "combiSglMax",
      name: "combiSglMax",
      label: "combi SglMax",
      value: highest.label,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  SglLim() {
    return {
      id: "SglLim",
      name: "Sgllim",
      label: "Sgl,lim",
      value: this.round(1.0, 3),
      description: "Coefficient de sécurité au glissement limite",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  verificationGlissement() {
    return [
      this.combiSglMax(),
      this.HdMax(),
      this.RhdMax(),
      this.SglMax(),
      this.SglLim()
    ]
  }

  Med() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Srv < b.Srv ? a : b);

    return {
      id: "Med",
      name: "Med",
      label: "Med (T.m)",
      value: this.round(highest.Mrv, 3),
      description: "Moment déstabilisant",
      unit: { value: 'Tm', label: 'T.m' },
      standard: "",
      reference: ""
    }
  }

  Mrd() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Srv < b.Srv ? a : b);

    return {
      id: "Mrd",
      name: "Mrd",
      label: "Mrd (T.m)",
      value: this.round(highest.Mst, 3),
      description: "Moment stabilisant",
      unit: { value: 'Tm', label: 'T.m' },
      standard: "",
      reference: ""
    }
  }

  SrvMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Sgl < b.Sgl ? a : b);
    return {
      id: "SrvMax",
      name: "SrvMax",
      label: "SrvMax",
      value: this.round(highest.Srv, 3),
      description: "Coefficient de sécurité au renversement",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  combiSrvMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Srv < b.Srv ? a : b);
    return {
      id: "combiSglMax",
      name: "combiSglMax",
      label: "combi SglMax",
      value: highest.label,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  SrvLim() {
    return {
      id: "SrvLim",
      name: "Srvlim",
      label: "Srv,lim",
      value: this.round(1.0, 3),
      description: "Coefficient de sécurité au renversement limite",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  verificationRenversement() {
    return [
      this.combiSrvMax(),
      this.Med(),
      this.Mrd(),
      this.SrvMax(),
      this.SrvLim()
    ]
  }

  SextMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.coefExt > b.coefExt ? a : b);
    return {
      id: "SextMax",
      name: "SextMax",
      label: "SextMax",
      value: this.round(highest.Sext, 3),
      description: "Valeur maximale de l'excentrement",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  combiSextMax() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.coefExt > b.coefExt ? a : b);
    return {
      id: "combiSextMax",
      name: "combiSextMax",
      label: "combi SextMax",
      value: highest.label,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  SextMaxLim() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.coefExt > b.coefExt ? a : b);
    return {
      id: "SextLim",
      name: "Sextlim",
      label: "Sext,lim",
      value: this.round(highest.SextLim, 3),
      description: "Coefficient de sécurité au renversement limite",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }




  verificationExcentrement() {
    return [
      this.combiSextMax(),
      this.SextMax(),
      this.SextMaxLim()
    ]
  }



  // Ls() {
  //   const dav = this.data.dav.value;
  //   const fav = this.data.fav.value;
  //   const ep = this.data.ep.value;
  //   const fam = this.data.fam.value;
  //   const dam = this.data.dam.value;
  //   return dav + fav + ep + fam + dam;
  // }

  e() {
    const M = 0;
    const V = 0;
    return M === 0 ? 0 : M / V;

  }

  sigmaCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssigma > b.Ssigma ? a : b);
    return {
      id: "sigmaCrit",
      name: "sigmaCrit",
      label: "sigma,crit (kPa)",
      value: this.round(highest.sigmaSol, 3),
      description: "Contrainte critique appliquée au sol",
      unit: { value: "kPa", label: "kPa" },
      standard: "",
      reference: ""
    }
  }

  sigmaLimCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssigma > b.Ssigma ? a : b);
    return {
      id: "sigmaLimCrit",
      name: "sigmaLimCrit",
      label: "sigma,lim (kPa)",
      value: this.round(highest.sigmaLim, 3),
      description: "Contrainte limite applicable au sol",
      unit: { value: "kPa", label: "kPa" },
      standard: "",
      reference: ""
    }
  }

  combiSigmaCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssigma > b.Ssigma ? a : b);
    return {
      id: "combiSigmaCrit",
      name: "combiSigmaCrit",
      label: "combi SigmaCrit",
      value: highest.label,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  verificationContrainteDuSol() {
    return [
      this.combiSigmaCrit(),
      this.sigmaCrit(),
      this.sigmaLimCrit(),
    ];
  }

  ScSurfaceComprimee() {
    const B = this.Ls();
    const e = this.e();
    return 3 * (B / 2 - e);
  }

  surfaceCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssurface > b.Ssurface ? a : b);
    return {
      id: "surfaceCrit",
      name: "surfaceCrit",
      label: "Sc (%)",
      value: Math.round(this.round(Math.min(1, highest.surfaceComprimee), 3) * 100 * 100) / 100,
      description: "Taux de compression critique appliquée au sol",
      unit: { value: "%", label: "%" },
      standard: "",
      reference: ""
    }
  }

  surfaceLimCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssurface > b.Ssurface ? a : b);
    return {
      id: "surfaceLimCrit",
      name: "surfaceLimCrit",
      label: "Smini (%)",
      value: Math.round(this.round(Math.min(1, highest.surfaceLim), 3) * 100 * 100) / 100,
      description: "Taux de compression critique appliquée au sol",
      unit: { value: "%", label: "%" },
      standard: "",
      reference: ""
    }
  }

  combiSurfaceCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssigma > b.Ssigma ? a : b);
    return {
      id: "combiSurfaceCrit",
      name: "combiSurfaceCrit",
      label: "combi SurfaceCrit",
      value: highest.label,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  verificationSurfaceComprimee() {
    return [
      this.combiSurfaceCrit(),
      this.surfaceCrit(),
      this.surfaceLimCrit()
    ];
  }


  sigmaLimCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Ssigma > b.Ssigma ? a : b);
    return {
      id: "sigmaLimCrit",
      name: "sigmaLimCrit",
      label: "sigma,lim (kPa)",
      value: this.round(highest.sigmaLim, 3),
      description: "Contrainte limite applicable au sol",
      unit: { value: "kPa", label: "kPa" },
      standard: "",
      reference: ""
    }
  }

  combiBearingCapacityCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Sbearing > b.Sbearing ? a : b);
    return {
      id: "combiBearingCapacityCrit",
      name: "combiBearingCapacityCrit",
      label: "combi Capacité Portante",
      value: highest.label,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  VdCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Sbearing > b.Sbearing ? a : b);
    return {
      id: "VdCrit",
      name: "VdCrit",
      label: "Vd(T)",
      value: highest.V,
      description: "Valeur de calcul de la composante verticale",
      unit: { value: "T", label: "T" },
      standard: "",
      reference: ""
    }
  }

  VdLimCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Sbearing > b.Sbearing ? a : b);
    return {
      id: "VdLimCrit",
      name: "VdLimCrit",
      label: "Vd,lim",
      value: highest.VdLim,
      description: "Capacité portante",
      unit: { value: "T", label: "T" },
      standard: "",
      reference: ""
    }
  }

  bearingCapacityCrit() {
    const calculStabiliteExterne = this.calculStabiliteExterne();
    let highest = calculStabiliteExterne.reduce((a, b) => a.Sbearing > b.Sbearing ? a : b);
    return {
      id: "combiBearingCapacityCrit",
      name: "combiBearingCapacityCrit",
      label: "combi Capacité Portante",
      value: highest.Sbearing,
      description: "Combinaison critique",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }

  verificationCapacitePortante() {
    return [
      this.combiBearingCapacityCrit(),
      this.VdCrit(),
      this.VdLimCrit(),
      this.bearingCapacityCrit()
    ];
  }






  bilanStabiliteExterne() {
    const Hd = this.HdMax().value;
    const Rhd = this.RhdMax().value;
    const sfGlissement = Rhd === 0 ? 0 : Hd / Rhd;
    const sfRenversement = this.SrvMax().value === 0 ? 0 : this.SrvLim().value / this.SrvMax().value;
    const ie = this.SextMax().value;
    const ieLim = this.SextMaxLim().value;
    const sfExcentrement = ie === 0 ? 0 : ieLim / ie;
    const sfSurface = this.surfaceCrit().value === 0 ? 0 : this.surfaceLimCrit().value / this.surfaceCrit().value;
    const Vd = this.VdCrit().value;
    const VdLim = this.VdLimCrit().value;
    const sfCapacitePortante = VdLim === 0 ? 0 : Vd / VdLim;
    return {
      id: "bilanStabiliteExterne",
      name: "bilanStabiliteExterne",
      label: "Bilan stabilite externe",
      value: [
        { name: "Glissement", value: sfGlissement, label: `Hd = ${Hd}T ${(Hd < Rhd) ? '<' : '>'} Rhd = ${Rhd}T` },
        // { name: "Renversement", value: sfRenversement },
        { name: "Excentrement", value: sfExcentrement, label: `ieLim = ${ieLim} ${(ie > ieLim) ? '<' : '>'} ie = ${ie}` },
        { name: "Capacité portante", value: sfCapacitePortante, label: `Vd = ${Vd}T ${(Vd < VdLim) ? '<' : '>'} VdLim = ${VdLim}T` },
        // { name: "Surface comprimée", value: sfSurface },
      ],
      description: "Bilan stabilite externe",
      unit: { value: "", label: "" },
      standard: "",
      reference: ""
    }
  }
};

export default ExternalStability;