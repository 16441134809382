const chartData = (data) => {

  let datasets = [];

  const nStructure = data.value.structure.length;
  data.value.structure.map((item, index) => {
    datasets.push({
      label: item.label,
      type: 'scatter',
      data: item.value,
      backgroundColor: [
        'rgba(191,191,191, 1)'
      ],
      borderColor: [
        'rgba(191,191,191,1)'
      ],
      borderWidth: 3,
      lineTension: 0,
      showLine: true,
      fill: 0
    });
  })

  const nSoil = data.value.soil.length;
  data.value.soil.map((item, index) => {
    datasets.push({
      // label: nStructure + index,
      label: item.label,
      type: 'scatter',
      data: item.value,
      backgroundColor: [
        'rgba(191,191,0, 0.5)'
      ],
      borderColor: [
        'rgba(191,191,0, 0)'
      ],
      borderWidth: 0,
      lineTension: 0,
      showLine: true,
      fill: 0
    });
  })

  data.value.water.map((item, index) => {
    datasets.push({
      // label: nStructure + nSoil + index,
      label: item.label,
      type: 'line',
      data: item.value,
      backgroundColor: [
        'rgba(255,255,255, 0)'
      ],
      borderColor: [
        'blue'
      ],
      borderWidth: 3,
      lineTension: 0,
      showLine: true,
      fill: 0
    });
  })

  data.value.loads.map((load) => {
    load.map((item, index) => {
      datasets.push({
        // label: nStructure + nSoil + index,
        label: item.label,
        type: 'scatter',
        data: item.value,
        backgroundColor: [
          'rgba(255,0,0, 0.5)'
        ],
        borderColor: [
          'rgba(255,0,0, 1)'
        ],
        borderWidth: 3,
        lineTension: 0,
        showLine: true,
        fill: false,
        // fill: 1,
        order: index + 1
      });
    })
  })

  console.log('datasets', datasets)

  return {
    labels: ['Scatter'],
    datasets
  };
};

export default chartData;
