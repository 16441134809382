import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import TableElem from '../../../../Components/TableElem';
import DataTable from '../../Components/DataTable/DataTable';
import Graphe from '../Graphe/Graphe';
import FerraillagePng from './MurSoutenementFerraillage.png';

const Resultats = ({
  state,
  handleChangeState
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="div">
          {`Stabilité externe`}
        </Typography>
      </Grid>
      <Grid item md={4}>
        <CardElem
          title="Glissement"
          subtitle="Vérification vis-à-vis du glissement"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "value", label: "valeur" },
            ]}
            rows={state.data.verificationGlissement}
          />
        </CardElem>
      </Grid>
      {/* <Grid item md={6}>
        <CardElem
          title="Renversement"
          subtitle="ELU - Vérification vis-à-vis du renversement"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "value", label: "valeur" },
            ]}
            rows={state.data.verificationRenversement}
          />
        </CardElem>
      </Grid> */}
      <Grid item md={4}>
        <CardElem
          title="Excentrement"
          subtitle="Vérification vis-à-vis du renversement"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "value", label: "valeur" },
            ]}
            rows={state.data.verificationExcentrement}
          />
        </CardElem>
      </Grid>
      <Grid item md={4}>
        <CardElem
          title="Capacité portante"
          subtitle="Vérification vis-à-vis de la capacité portante"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "value", label: "valeur" },
            ]}
            rows={state.data.verificationCapacitePortante}
          />
        </CardElem>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="div">
          {`Stabilité interne`}
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <CardElem
              title="Armatures"
              subtitle="ELU - Calcul des sections d'aciers minimales"
            >
              <DataTable
                columns={[
                  { value: "name", label: "nom" },
                  { value: "value", label: "valeur" },
                ]}
                rows={state.data.bilanStabiliteInterne.value}
              />
            </CardElem>
          </Grid>
          <Grid item sm={6} style={{ textAlign: 'center' }}>
            <img src={FerraillagePng} style={{ maxWidth: "100%", height: "auto", padding: 0, margin: 0 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Resultats;