class Documentation {
  constructor() {

  }

  getAll() {
    const doc = {
      dav: {
        id: "dav",
        name: "dav",
        label: "dav",
        description: "Débord de semelle aval",
        unit: { value: 'm', label: 'm' },
        standard: "",
        reference: ""
      },
      fav: {
        id: "fav",
        name: "fav",
        label: "fav",
        description: "Fruit parement face aval",
        unit: { value: "m", label: "m" },
        standard: "",
        reference: ""
      },
      ep: {
        id: "ep",
        name: "ep",
        label: "ep",
        description: "Epaisseur parement",
        unit: { value: "m", label: "m" },
        standard: "",
        reference: ""
      },
      fam: {
        name: "fam",
        label: "fam",
        description: "Fruit de semelle amont",
        unit: { value: "m", label: "m" },
        standard: "",
        reference: ""
      },
      dam: {
        name: 'dam',
        text: 'dam',
        description: "Débord de semelle amont",
        unit: { name: 'm', text: 'm' },
        standard: "",
        reference: ""
      },
      zp: {
        name: 'zp',
        text: 'zp',
        description: "Elévation du sommet du parement",
        unit: { name: 'm', text: 'm NGF' },
        standard: "",
        reference: ""
      },
      Hp: {
        name: 'Hp',
        text: 'Hp',
        description: "Hauteur du parement",
        unit: { name: 'm', text: 'm' },
        standard: "",
        reference: ""
      },
      es: {
        name: 'es',
        text: 'es',
        description: "Epaisseur de semelle",
        unit: { name: 'm', text: 'm' },
        standard: "",
        reference: ""
      },
      Hb: {
        id: "Hb",
        name: "Hb",
        label: "Hb",
        description: "Hauteur de bêche",
        unit: { value: "m", label: "m" },
        standard: "",
        reference: ""
      },
      Lb: {
        id: "Lb",
        name: "Lb",
        text: "Lb",
        description: "Largeur de bêche",
        unit: { value: "m", label: "m" },
        standard: "",
        reference: ""
      },
      xbec: {
        id: "xbec",
        name: "xbec",
        label: "xbec",
        description: "Distance de la bêche par rapport à O",
        unit: { name: "m", label: "m" },
        standard: "",
        reference: ""
      },
      Ls: {
        name: 'Ls',
        text: 'Ls',
        description: "Largeur total de la semelle",
        unit: { name: 'm', text: 'm' },
        standard: "",
        reference: ""
      },
      SML: [{ x: 0, y: 0 }],
    }

    return doc;
  }
};

export default Documentation;