import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import CardElem from '../../Components/CardElem';
import InputElem from '../../Components/InputElem';
import TableElem from '../../Components/TableElem';
import initialState from './Utils/initialState';
import { RetainingWall } from './Utils/Calculations';
import Graphe from './Scenes/Graphe/Graphe';
import Projet from './Scenes/Projet/Projet';
import Structure from './Scenes/Structure/Structure';
import Sol from './Scenes/Sol/Sol';
import Charges from './Scenes/Charges/Charges';
import Resultats from './Scenes/Resultats/Resultats';
import Parametres from './Scenes/Parametres/Parametres';
import StabiliteExterne from './Scenes/StabiliteExterne/StabiliteExterne';
import StabiliteInterne from './Scenes/StabiliteInterne/StabiliteInterne';
import Bilan from './Scenes/Bilan/Bilan';

function EC7MurSoutenement() {
  const [state, setState] = useState(initialState);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const init = async () => {
      const calculatedData = new RetainingWall(initialState.data).analysis();
      const updatedState = {
        ...state,
        data: {
          ...state.data,
          ...calculatedData
        }
      }

      console.log('updatedState', updatedState)

      setState(updatedState);
    }
    init();
  }, []);

  const handleChangeState = (prop) => (event) => {
    const newData = {
      ...state.data,
      [prop]: {
        ...state.data[prop],
        value: Number(event.target.value)
      }
    };

    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }
    console.log('updatedState ', updatedState.beta)
    setState(updatedState);
  };

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CardElem
          title="Calcul de mur de soutènement"
          subtitle="Application de calcul de mur de soutênement aux Eurocodes"
        >
          <Bilan
            state={state}
            setState={setState}
          />
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Projet" {...a11yProps(0)} />
                <Tab label="Structure" {...a11yProps(1)} />
                <Tab label="Sol" {...a11yProps(2)} />
                <Tab label="Charges" {...a11yProps(3)} />
                <Tab label="Résultats" {...a11yProps(4)} />
                <Tab label="Paramètres" {...a11yProps(5)} />
                <Tab label="Stabilité externe" {...a11yProps(6)} />
                <Tab label="Stabilité interne" {...a11yProps(7)} />
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              <Projet
                state={state}
                setState={setState}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Structure
                state={state}
                handleChangeState={handleChangeState}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Sol
                state={state}
                handleChangeState={handleChangeState}
              />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Charges
                state={state}
                setState={setState}
              />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <Resultats
                state={state}
                handleChangeState={handleChangeState}
              />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <Parametres
                state={state}
                setState={setState}
                handleChangeState={handleChangeState}
              />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <StabiliteExterne
                state={state}
                handleChangeState={handleChangeState}
              />
            </TabPanel>
            <TabPanel value={tab} index={7}>
              <StabiliteInterne
                state={state}
                handleChangeState={handleChangeState}
              />
            </TabPanel>
          </Box>
        </CardElem>
      </Grid>
    </Grid >
  );
}

export default EC7MurSoutenement;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}