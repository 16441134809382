import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import TableElem from '../../../../Components/TableElem';
import DataTable from '../../Components/DataTable/DataTable';
import Graphe from '../Graphe/Graphe';

const StabiliteInterne = ({
  state,
  handleChangeState
}) => {
  return (
    <Grid container spacing={3}>
      {/* <Grid item sm={12}>
        <Typography gutterBottom variant="h6" component="div">
          {`Géométrie du mur`}
        </Typography>
      </Grid> */}
      <Grid item md={12}>
        <CardElem
          title="Bilans des forces par cas de charges"
          subtitle="Calcul du bilan des forces par cas de charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "description", label: "description" },
              { value: "value", label: "Cas de charge" },
              { value: "N", label: "V(T)" },
              { value: "V", label: "H(T)" },
              { value: "M", label: "M(T.m)" },
            ]}
            rows={state.data.recapitulatifSollicitations}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
      <Grid item md={12}>
        <CardElem
          title="Bilans des forces par combinaison de charges"
          subtitle="Calcul du bilan des forces par combinaison de charges"
        >
          <DataTable
            columns={[
              { value: "label", label: "nom" },
              { value: "type", label: "type" },
              { value: "categorie", label: "categorie" },
              { value: "N", label: "V(T)" },
              { value: "V", label: "H(T)" },
              { value: "M", label: "M(T.m)" },
              // { value: "Asc", label: "Asc(cm²)" },
              { value: "mub", label: "mub" },
              { value: "zub", label: "zub(m)" },
              { value: "Asb", label: "Asb(cm²)" },
              { value: "Nsemelle", label: "Vsemelle(T)" },
              { value: "Msemelle", label: "Msemelle(T.m)" },
              { value: "e", label: "e(m)" },
              { value: "sigma1", label: "sigma1(kPa)" },
              { value: "sigma2", label: "sigma2(kPa)" },
              { value: "MedG", label: "MedG(T.m)" },
              { value: "MedD", label: "MedD(T.m)" },
              { value: "Asfi", label: "Asfi(cm²)" },
              { value: "Asfsup", label: "Asfsup(cm²)" },
            ]}
            rows={state.data.calculStabiliteInterne}
          // onChange={handleChangeState}
          />
        </CardElem>
      </Grid>
    </Grid>
  )
}

export default StabiliteInterne;