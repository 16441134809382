import Mathematic from './Mathematic';
import GeometryFunctions from './GeometryFunctions';

class General extends Mathematic {
  constructor(data) {
    super(data);
    this.data = data;
    this.geometryFunctions = new GeometryFunctions(data);

  }
}

export default General