import React from 'react';
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import CardElem from '../../../../Components/CardElem';
import TableElem from '../../../../Components/TableElem';
import SelectElem from '../../../../Components/SelectElem';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Graphe from '../Graphe/Graphe';
import { RetainingWall } from '../../Utils/Calculations';
import NumberFormat from 'react-number-format';

const Charges = ({
  state,
  setState
}) => {

  const handleChangeLoads = (e, index, type, key) => {
    const loads = state.data[type].value;
    const newLoads = loads.map((load, i) => {
      if (i === index) {
        if (typeof load[key] === "number") {
          return { ...load, [key]: Number(e.target.value) };
        }
        return { ...load, [key]: e.target.value };
      }
      return load
    });
    const newData = {
      ...state.data,
      [type]: {
        ...state.data[type],
        value: [...newLoads]
      }
    };
    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }

    setState(updatedState);
  }

  const handleAddData = () => {
    const n = state.data.chargesSurRemblai.value.length;
    const newData = {
      ...state.data,
      chargesSurRemblai: {
        ...state.data.chargesSurRemblai,
        value: [
          ...state.data.chargesSurRemblai.value,
          {
            id: `S${n}`,
            name: `S${n}`,
            label: `S${n}`,
            type: "surfacique",
            loadCase: "Q",
            description: "Charge surfacique avec une valeur Qi à une distance xi du parement sur le remblai amont et une valeur Qj à une distance xj du parement",
            xi: 0,
            xj: 0,
            Qi: 0,
            Qj: 0
          },
        ]
      }
    };
    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }
    console.log('updateState', updatedState)
    setState(updatedState);
  }
  const handleChangeQ = (e, index, type) => {
    const loads = state.data[type].value;
    const newLoads = loads.map((load, i) => {
      if (i === index) {
        return { ...load, Qi: Number(e.target.value), Qj: load.type === "surfacique"? Number(e.target.value):0 };
      }
      return load
    });
    const newData = {
      ...state.data,
      [type]: {
        ...state.data[type],
        value: [...newLoads]
      }
    };
    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }
    console.log(`Qi ${e.target.value}`)
    setState(updatedState);
  }

  const handleRemoveData = (index) => {
    const loads = [...state.data.chargesSurRemblai.value];
    const newLoads = loads.filter((item, i) => i !== index)

    const newData = {
      ...state.data,
      chargesSurRemblai: {
        ...state.data.chargesSurRemblai,
        value: [...newLoads]
      }
    };

    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }

    setState(updatedState);
  }

  const handleRemoveAll = () => {
    const newData = {
      ...state.data,
      chargesSurRemblai: {
        ...state.data.chargesSurRemblai,
        value: []
      }
    };
    const calculatedData = new RetainingWall(newData).analysis();

    const updatedState = {
      ...state,
      data: {
        ...newData,
        ...calculatedData
      }
    }

    setState(updatedState);
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item sm={12}>
        <Typography gutterBottom variant="h6" component="div">
          {`Géométrie du mur`}
        </Typography>
      </Grid> */}
      <Grid item md={6}>
        {/* <CardElem
          title="Charges sur Tête de Mur"
          subtitle="Charges appliquées sur la tête du mur de soutenement"
        >
          <TableContainer component={Paper}>
            <Table className="table" size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Cas de charge</TableCell>
                  <TableCell>{`V(T)`}</TableCell>
                  <TableCell>{`H(T)`}</TableCell>
                  <TableCell>{`M(T.m)`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.chargesSurTeteMur.value.map((charge, index) => (
                  <TableRow key={`${index}`}>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        value={charge['label']}
                        disableUnderline={true}
                      // onChange={onChange(data[`${row}`]['id'])}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        value={`${charge['type']}`}
                        disableUnderline={true}
                        disable={true}
                      // onChange={onChange(data[`${row}`]['id'])}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <SelectElem
                        type="text"
                        value={charge['loadCase']}
                        list={state.data.casDeCharges.value}
                      // onChange={onChange(data[`${row}`]['id'])}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        value={charge['V']}
                        disableUnderline={true}
                      // onChange={onChange(data[`${row}`]['id'])}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        value={charge['H']}
                        disableUnderline={true}
                      // onChange={onChange(data[`${row}`]['id'])}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        value={charge['M']}
                        disableUnderline={true}
                      // onChange={onChange(data[`${row}`]['id'])}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardElem> */}
        <CardElem
          title="Charges sur remblai"
          subtitle="Charges appliquées sur le remblai"
        >
          <Typography gutterBottom variant="body" component="div">
          {`Remarques:`}
            <ul>
              <li>{`xi et xj sont les distances d'application des charges à partir de la face amont du mur`}</li>
              <li>{`xj et Qj sont ne sont utilisés que pour les charges surfaciques`}</li>
              <li>{`La charge linéique est considérée parallèle au mur de soutenement`}</li>
            </ul>

          </Typography>
          <div style={{ width: "100%", textAlign: "right", marginBottom: "1em" }}>
            <ButtonGroup variant="contained" color="black" aria-label="outlined primary button group">
              <IconButton
                aria-label="add"
                color="secondary"
                onClick={handleAddData}
              >
                <AddCircleIcon />
              </IconButton>
              <IconButton
                aria-label="add"
                color="secondary"
                onClick={handleRemoveAll}
              >
                <DeleteIcon />
              </IconButton>
            </ButtonGroup>
          </div>
          <TableContainer component={Paper}>
            <Table className="table" size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Cas de charge</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  <TableCell>{`xi(m)`}</TableCell>
                  <TableCell>{`Qi(T/m)`}</TableCell>
                  <TableCell>{`xj(m)`}</TableCell>
                  <TableCell>{`Qj(T/m)`}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.chargesSurRemblai.value.map((charge, index) => (
                  <TableRow key={`${index}`}>
                    <TableCell scope="row">
                      <Input
                        type="text"
                        value={charge['label']}
                        disableUnderline={true}
                        onChange={e => {
                          // handleChangeLoads(e, index, "chargesSurRemblai", "id");
                          handleChangeLoads(e, index, "chargesSurRemblai", "name");
                          handleChangeLoads(e, index, "chargesSurRemblai", "label");
                        }}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <SelectElem
                        type="text"
                        value={charge['type']}
                        list={state.data.typeEfforts.value}
                        onChange={(e) => handleChangeLoads(e, index, "chargesSurRemblai", "type")}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <SelectElem
                        type="text"
                        value={charge['loadCase']}
                        list={state.data.casDeCharges.value}
                        onChange={(e) => handleChangeLoads(e, index, "chargesSurRemblai", "loadCase")}
                      />
                    </TableCell>
                    {/* <TableCell scope="row">
                      {`${charge['description']}`}
                    </TableCell> */}
                    <TableCell scope="row">
                      <NumberFormat
                        style={{ border: 0, color: "blue", height: '100%', width: '100%', margin: 0 }}
                        type="text"
                        disableUnderline={true}
                        onChange={(e) => handleChangeLoads(e, index, "chargesSurRemblai", "xi")}
                        value={charge['xi']}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <NumberFormat
                        style={{ border: 0, color: "blue", height: '100%', width: '100%', margin: 0 }}
                        type="text"
                        disableUnderline={true}
                        onChange={(e) => handleChangeQ(e, index, "chargesSurRemblai")}
                        value={charge['Qi']}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <NumberFormat
                        style={{ border: 0, color: `${charge['type'] === 'surfacique'? 'blue': 'grey'}`, height: '100%', width: '100%', margin: 0 }}
                        type="text"
                        disableUnderline={true}
                        onChange={(e) => handleChangeLoads(e, index, "chargesSurRemblai", "xj")}
                        value={charge['xj']}
                        disabled={!(charge['type'] === 'surfacique')}
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <NumberFormat
                        style={{ border: 0, color: `${charge['type'] === 'surfacique'? 'blue': 'grey'}`, height: '100%', width: '100%', margin: 0 }}
                        type="text"
                        disableUnderline={true}
                        //onChange={(e) => handleChangeLoads(e, index, "chargesSurRemblai", "Qj")}
                        value={charge['Qj']}
                        disabled
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <IconButton
                        aria-label="delete"
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveData(index)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardElem>
      </Grid>
      <Grid item md={6} style={{ width: '100%' }}>
        <Graphe
          state={state}
        />
      </Grid>
    </Grid>
  )
}

export default Charges;